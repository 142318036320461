import React, { useState, useEffect } from "react";
import "../../CommonCssFiles/planstyle.css";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Paper,
  Skeleton,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { Proposal, ProposerForm } from "../ProposalPage/Proposal";
import { findSelf, getDate } from "../../helper/common";
import Summary from "../../commonComponents/Summary";
import { payload } from "../../helper/godigit";
import { payload as carepayload } from "../../helper/carehealth";
import { payload as icicipayload } from "../../helper/icici";
import { payload as reliancepayload } from "../../helper/reliance";
import { payload as hdfcpayload } from "../../helper/hdfc";
import { companyId, sha512 } from "../../helper/common";
import axios from "axios";
import FormData from "form-data";
import Loader from "../../../AllPageComponents/Loader";
import "./Kycformdata.css";
import locations from "../../../locations.json";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import calculateAge from "../AfterPlanFetchPage/AgeModalHelper";
import FileBase64 from "react-file-base64";
import { uploadImageBase64 } from "../../Api/Api";

import {
  selectProposal,
  updateData,
  addInsuredData,
  updateInsuredData,
  selectHDFCcheckedQuestion,
  selectICICIcheckedQuestion,
  updatenamesekyc,
  selectReliancecheckedQuestion,
  updatePinKyc,
  addfield,
  settingGender,
  addkycfield,
  updateSateKyc,
} from "../../redux/Slice";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import {
  afterPlanFetchApi,
  getReduxData,
  settingReduxData,
} from "../../Api/Api";
import Swal from "sweetalert2";
import Redirect from "../../../AllPageComponents/Rederict";
import { Button, Box, Typography, Chip } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select";
import { validate } from "uuid";
// import { isValid } from "react-datepicker/dist/date_utils";

const KYCfrom = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };

  const [apiResponse, setApiResponse] = useState();
  const [addonArr, setAddonArr] = useState();
  const [addonsArray, setAddonsArray] = useState();
  const [addonDataList, setAddonDataList] = useState();
  const [loader, setLoader] = useState(false);
  const [stopLoading, setStopLoading] = useState();

  const [kycData, setKycData] = useState();
  const [proposerData, setProposerData] = useState();

  const [pinCodeList, setPinCodeList] = useState([]);

  const [firstNameErr, setFirstNameErr] = useState();
  const [middleNameErr, setMiddleNameErr] = useState();
  const [lastNameErr, setLastNameErr] = useState();
  const [kycAddressError, setKycAddressError] = useState();

  const [docTypeErr, setDocTypeErr] = useState();
  const [dobErr, setDobErr] = useState();
  const [docNumErr, setDocNumErr] = useState();
  const [nomNameErr, setNomNameErr] = useState();
  const [nomDobErr, setNomDobErr] = useState();
  const [nomRelErr, setNomRelErr] = useState();
  const [nomAddressError, setNomAddressError] = useState();
  const [nomPhoneError, setNomPhoneError] = useState();
  const [emailError, setEmailError] = useState();
  const [pinError, setPinError] = useState();
  const [nameError, setNameError] = useState();
  const [accountError, setAccountError] = useState("");
  const [ifscError, setIfscError] = useState(""); // To store error messages
  const [bankNameError, setBankNameError] = useState(""); // To store error messages

  const [finalAddonList, setFinalAddonList] = useState();
  // State hooks for document and identity files
  const [selectedFile, setSelectedFile] = useState(null); // Document file (Front)
  const [selectedBackFile, setSelectedBackFile] = useState(null); // Document file (Back)
  const [selectedIdentityFile, setSelectedIdentityFile] = useState(null); // Identity Proof file (Front)
  const [selectedIdentityBackFile, setSelectedIdentityBackFile] =
    useState(null); // Identity Proof file (Back)
  const [fileError, setFileError] = useState("");
  const [identityFileError, setIdentityFileError] = useState("");

  const [identityFileErrorBack , setIdentityFileErrorBack ] = useState("");
  const [fileErrorBack , setFileErrorBack] = useState('');


  const [selectedIDType, setSelectedIDType] = useState("");
  const [selectedDocumentDrop, setSelectedDocumentDrop] = useState("");

  const [docFront, setDocFront] = useState("");
  const [docBack, setDocBack] = useState("");
  const [idFront, setIdFront] = useState("");
  const [idback, setIdBack] = useState("");
  const [kycpath, setKycPath] = useState({
    docfrontpath: "",
    docbackpath: "",
    idfrontpath: "",
    idbackpath: "",
  });

  const [kycExtension, setKycExtension] = useState({
    docfrontext: "",
    docbackext: "",
    idfrontext: "",
    idbackext: "",
  });

  const handleChange = (event) => {
    setSelectedIDType(event.target.value);
  };
  const handleChangeDrop = (event) => {
    setSelectedDocumentDrop(event.target.value);
  };
  // Allowed file formats and max size
  // const allowedFormats = [
  //   "image/jpeg",
  //   "image/png",
  //   "application/pdf",
  //   "image/tiff",
  //   "image/jpg",
  // ];
  // const maxFileSize = 5 * 1024 * 1024; // 5MB

  const allowedFormats = [
    "application/pdf", // PDF
    "image/jpeg", // JPEG and JPG
    "image/jpg", // JPG (alternative)
    "image/png", // PNG
    "image/tiff", // TIFF
    "image/tif", // TIF (alternative)
  ];

  const maxFileSize = 5 * 1024 * 1024; // 5MB

  const fetchPlan = async () => {
    try {
      setLoader(true); // Start loading
      const response = await afterPlanFetchApi(unique_id, c_id, plan_id);
      console.log("the response in kycform", response);

      if (response.data.response == null) {
        setStopLoading(true);
        handleError("Oops there is an error !");
      }

      setApiResponse(response.data);
      setAddonDataList(response.data.request.addonPrice);
      setFinalAddonList(response.data.request.addonPrice);

      //   setHdfcPremium(response.data.response.hdfc_premium);

      if (response.data.request.addons) {
        let currentAddons = response.data.request.addons
          ? response.data.request.addons.split(",")
          : [];

        setAddonArr(currentAddons);
        setAddonsArray(currentAddons);
      }

      setLoader(false);
    } catch (error) {
      console.log("the error in afterfetchplan", error);
      setStopLoading(true);
      handleError("Oops there is an error !");
    } finally {
      setLoader(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const retry_kyc = params.get("retry_kyc");

  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");
  const urldata = params.get("url_data");
  const company = companyId();

  let pindodeList;
  let filterLocation;

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    setStopLoading(true);
  };

  const callApi = () => {
    setLoader(true);

    //console.log("fetchPlandata", fetchPlandata);
    //console.log("updatedPlandata", updatedPlandata);
    // //console.log('proposalData',proposalData);
    let final_payload = {};
    let url;
    if (c_id == "Care") {
      final_payload = carepayload(
        // proposalData,
        apiResponse.response,
        // proposalData,
        addonsArray
      );
      url =
        process.env.REACT_APP_BASE_URL + "health/createqoute/care/" + unique_id;
      // url = "http://localhost/backend_api/api/v1/health/createqoute/care"
    } else if (c_id == "GoDigit") {
      final_payload = payload(
        // proposalData,
        apiResponse.response,
        // proposalDataKyc,
        addonsArray
      );
      url =
        process.env.REACT_APP_BASE_URL +
        "health/createqoute/godigit/" +
        unique_id;
    } else if (c_id == "ICICI") {
      final_payload = icicipayload(
        // proposalData,
        apiResponse.response,
        // proposalDataKyc,
        addonsArray
        // ICICIcheckedQuestion
      );
      url =
        process.env.REACT_APP_BASE_URL +
        "health/createqoute/icici/" +
        unique_id;
    } else if (c_id == "hdfc") {
      final_payload = hdfcpayload(
        // proposalData,
        apiResponse.response,
        // proposalDataKyc,
        addonsArray,
        // HDFCcheckedQuestion,
        apiResponse.response.QuoteNo,
        apiResponse.response.quoteId,
        // HDCRiderPremium,
        // hdfcPremium,
        apiResponse.request
      );

      console.log("final Payload", final_payload);
      url =
        process.env.REACT_APP_BASE_URL + "health/createqoute/hdfc/" + unique_id;
    } else if (c_id == "reliance") {
      final_payload = reliancepayload(
        // proposalData,
        apiResponse.response,
        // proposalDataKyc,
        addonsArray
        // ReliancecheckedQuestion
      );
      console.log("the final payload reliance", final_payload);
      url =
        process.env.REACT_APP_BASE_URL +
        "health/createqoute/reliance/" +
        unique_id;
    }

    console.log("final_payload : ", final_payload);

    let data = JSON.stringify(final_payload);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("the main kyc response", response.data);
        // alert(JSON.stringify(response.data))
        setLoader(false);
        // set_error(true, response.data.message);

        // setLoader(false)
        if (c_id == "Care") {
          // window.location.href = "http://localhost/backend_api/api/v1/health/payment/care/"+response.data.intPolicyDataIO.policy.proposalNum;

          if (response.data.status == true) {
            window.location.href =
              process.env.REACT_APP_BASE_URL +
              "health/payment/care/" +
              response.data.data.intPolicyDataIO.policy.proposalNum +
              "/" +
              unique_id;
          } else {
            // alert(response.data.message)
            // set_error(true, response.data.message);
          }
        } else if (c_id == "GoDigit") {
          let resData = response.data.data;

          if (response.data.status == true) {
            // console.log(
            //   "GoDigit paymentLink",
            //   resData["Retail Health-Create Quote"].paymentLink
            // );

            if (resData.hasOwnProperty("Retail Health-Create Quote")) {
              window.location.href =
                resData["Retail Health-Create Quote"].paymentLink;
            }
          } else {
            // alert(resData.message)
            // set_error(true, response.data.message);
          }
        } else if (c_id == "ICICI") {
          if (response.data.status == true) {
            const correlationId = response.data.data.correlationId;
            // alert(correlationId)
            window.location.href =
              process.env.REACT_APP_BASE_URL +
              "health/payment/icici/" +
              correlationId +
              "/" +
              final_payload.Premium +
              "/" +
              response.data.data.proposalNumber +
              "/" +
              response.data.data.customerId +
              "/" +
              unique_id +
              "/" +
              plan_id;
          } else {
            // set_error(true, response.data.message);
          }
        } else if (c_id == "hdfc") {
          if (response.data.status == true) {
            // console.log(
            //   "HDFC PaymentTransactionNo",
            //   response.data.data.Data.PaymentTransactionNo
            // );
            const transactionNo = response.data.data.Data.PaymentTransactionNo;
            const inputString = "GTOPHDFC|" + transactionNo + "|GTOP@7583|S001";
            sha512(inputString).then((hashedString) => {
              window.location.href =
                process.env.REACT_APP_BASE_URL +
                "health/payment/hdfc/" +
                transactionNo +
                "/S001/" +
                hashedString +
                "/" +
                unique_id;
            });
          } else {
            // alert(response.data.message)
            // set_error(true, response.data.message);
          }
        } else if (c_id == "reliance") {
          if (response.data.status == true) {
            console.log("reliance createqoute", response.data);
            let proposalAmount = response.data.data.Premium.FinalPremium;
            let kyc = response.data.data.ckyc;
            let proposalNo = response.data.data.Policy.ProposalNo;

            let url =
              process.env.REACT_APP_BASE_URL +
              "health/payment/reliance/" +
              proposalNo +
              "/" +
              proposalAmount +
              "/" +
              kyc +
              "/" +
              unique_id;
            // alert(url);

            window.location.href = url;
          } else {
            // alert(response.data.message)
            // set_error(true, response.data.message);
          }
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // Nomine relation for Relince

  const relincenomine = [
    { value: "Self", label: "Self" },
    { value: "Spouse", label: "Spouse" },
    { value: "Son", label: "Son" },
    { value: "Employee", label: "Employee" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
  ];

  const hdfcnomine = [
    { value: "Wife", label: "Wife" },
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
    { value: "Father-in-law", label: "Father-in-law" },
    { value: "Mother-in-law", label: "Mother-in-law" },
    { value: "Husband", label: "Husband" },
  ];

  // Nomine for others

  const bankNamesData = [
    {
      BankCode: 1664,
      BankName: "Pachora People's Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1665,
      BankName: "Padmashri Dr.Vithalrao Vikhe Patil Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1666,
      BankName: "Pandharpur Marchant's Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1667,
      BankName: "Pandharpur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1668,
      BankName: "Parner Taluka Sainik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1669,
      BankName: "Peoples Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1670,
      BankName: "Pimpalgaon Merchants'Co-op. BAnk Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1671,
      BankName: "Poornawadi Nagrik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1672,
      BankName: "Pragati Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1673,
      BankName: "Priyadarshani Mahila Nagri Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1674,
      BankName: "Priyadarshani Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1675,
      BankName: "Priyadarshani Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1676,
      BankName: "Pusad Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1677,
      BankName: "Rajlaxmi Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1678,
      BankName: "Rajmata Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1679,
      BankName: "Ratanchand Shaha Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1680,
      BankName: "Raver People's Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1681,
      BankName: "Ravi Commercial Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1682,
      BankName: "Rukmini Sahakati Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1683,
      BankName: "Sadguru Gahininath Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1684,
      BankName: "Saibaba Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1685,
      BankName: "Saibaba Nagari Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1686,
      BankName: "Samarth Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1687,
      BankName: "Samarth Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1688,
      BankName: "Samarth Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1689,
      BankName: "Samruddhi Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1690,
      BankName: "Samruddhi Sahkari Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1691,
      BankName: "Sangamner Merchant's Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1692,
      BankName: "Sangola Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1693,
      BankName: "Sanmitra Mahila Nagri Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1694,
      BankName: "Sanmitra Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1695,
      BankName: "Saraswathi Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1696,
      BankName: "Satana Merchants' Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1697,
      BankName: "Shahada People's Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1698,
      BankName: "Shankar Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1699,
      BankName: "Shankarrao Chavan Nagri Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1700,
      BankName: "Shankerrao Mohite-Patil Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1701,
      BankName: "Sharad Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1702,
      BankName: "Shirpur Merchants Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1703,
      BankName: "Shirpur Peoples Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1704,
      BankName: "Shivaji Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1705,
      BankName: "Shivparvati Mahila Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1706,
      BankName: "Shivshakti Urban Co-op BnkLtd",
      ParentCode: 0,
    },
    {
      BankCode: 1707,
      BankName: "Shree Agrasen Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1708,
      BankName: "Shree Gajanan Maharaj Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1709,
      BankName: "Shree Govardhansingji Raghuvashi Sahakari",
      ParentCode: 0,
    },
    {
      BankCode: 1710,
      BankName: "Shree Mahavir Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1711,
      BankName: "Shree Mahesh Co-operative Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1712,
      BankName: "Shree Samarth  Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1713,
      BankName: "Shree Vyas Dhanvarsha Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1714,
      BankName: "Shri Anand Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1715,
      BankName: "Shri Bhausaheb Thorat Amrutvahini Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1716,
      BankName: "Shri Chhatrapati Rajarshi Shahu Urban Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1717,
      BankName: "Shri Gajanan Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1718,
      BankName: "Shri Kanyaka Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1719,
      BankName: "Shri Mahaveer Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1720,
      BankName: "Shri Rukmini Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1721,
      BankName: "Shri Shiveshwar Nagri Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 790,
      BankName: "Merchants' Liberal Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 791,
      BankName: "Merchants' Souharda Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 792,
      BankName: "Merchants' Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 793,
      BankName: "Mudalgi  Co operative  Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 794,
      BankName: "Mudgal Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 795,
      BankName: "Mulgund Urban Souharda Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 796,
      BankName: "Muslim Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 797,
      BankName: "Mysore Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 798,
      BankName: "Mysore Merchant's Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 799,
      BankName: "Mysore Silk Cloth Merchants' Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 800,
      BankName: "Mysore Zilla Mahila Sahakara Bank Niyamitha.",
      ParentCode: 0,
    },
    {
      BankCode: 801,
      BankName: "Narayanaguru Urban Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 802,
      BankName: "National Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 803,
      BankName: "Navanagara Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 804,
      BankName: "Nehru Nagar Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 805,
      BankName: "Nesargi Urban Co-operative Credit Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 806,
      BankName: "Nyayamitra Sahakari Bank Niyamita",
      ParentCode: 0,
    },
    {
      BankCode: 807,
      BankName: "Pachhapur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 808,
      BankName: "Pioneer Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 809,
      BankName: "Ponnampet Town Co-operative  Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 810,
      BankName: "Pragathi Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 811,
      BankName: "Pragathi Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 812,
      BankName: "Priyadarshini Mahila Urban Sahakari Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 813,
      BankName: "Puttur Co-operative Town Bank Ltd. Puttur",
      ParentCode: 0,
    },
    {
      BankCode: 814,
      BankName: "Raddi Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 815,
      BankName: "Railway Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 816,
      BankName: "Rajajinagar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 817,
      BankName: "Ramanagaram Urban Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 818,
      BankName: "Reserve Bank Employees' Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 819,
      BankName: "Ron Taluka Primary Teachers' Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 820,
      BankName: "Sadalga Urban Souharda Sahakari Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 821,
      BankName: "Sahasrarjun Seva Kalyan Co-operative  Bank",
      ParentCode: 0,
    },
    {
      BankCode: 822,
      BankName: "Sahyadri Mahila Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 823,
      BankName: "Sandur Pattana Souharda Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 824,
      BankName: "Sarakari Naukarara Sahakari Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 825,
      BankName: "Savanur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 826,
      BankName: "Shedbal Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 827,
      BankName: "Shiggaon Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 828,
      BankName: "Shimoga Arecanut Mandy Merchants Co-",
      ParentCode: 0,
    },
    {
      BankCode: 829,
      BankName: "Shimsha Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 830,
      BankName: "Shiva Sahakari Bank Niyamita",
      ParentCode: 0,
    },
    {
      BankCode: 831,
      BankName: "Shiva Sahakari Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 832,
      BankName: "Shree Basaveshwar Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 833,
      BankName: "Shree Basaveshwar Urban Co-operative Bank Ltd. Ranebennur",
      ParentCode: 0,
    },
    {
      BankCode: 834,
      BankName: "Shree Gajanan Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 835,
      BankName: "Shree Mahabaleshwar Co operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 836,
      BankName: "Shree Mahalaxmi Urban Co-operative Credit",
      ParentCode: 0,
    },
    {
      BankCode: 837,
      BankName: "Shree Murugharajendra Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 838,
      BankName: "Shree Tukaram  Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 839,
      BankName: "Shri Basaveshwar Sahakari Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 840,
      BankName: "Shri Chatrapati Shivaji Maharaj Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 841,
      BankName: "Shri D.T.Patil Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 842,
      BankName: "Shri Gurudev Brahmanand Pattana Sahakara Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 843,
      BankName: "Shri Gurusiddheshwar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 844,
      BankName: "Shri Kadasiddeshwar Pattan Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 845,
      BankName: "Shri Mahalaxmi Pattan Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 846,
      BankName: "Shri Mahant Shivayogi Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1722,
      BankName: "Shri Swami Samarth Sahakari Bank Ltd.Nighoj",
      ParentCode: 0,
    },
    {
      BankCode: 1723,
      BankName: "Shri Vyankatesh Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1724,
      BankName: "Shrikrishna Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1725,
      BankName: "Shriram Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1726,
      BankName: "Siddheshwar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1727,
      BankName: "Siddheshwar Urban Co-operative Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1728,
      BankName: "Solapur Siddheshwar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1729,
      BankName: "Solapur Social Urban Co-op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1730,
      BankName: "Sonpeth Nagri Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1731,
      BankName: "Standard  Urban Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1732,
      BankName: "Sundarlal Sawji Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1733,
      BankName: "Terna Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1734,
      BankName: "The Agrasen Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1735,
      BankName: "The Amravati Merchants' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1736,
      BankName: "The Amravati Zilla Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1737,
      BankName: "The Amravati Zilla-Parishad Shikshak Sahakari",
      ParentCode: 0,
    },
    {
      BankCode: 1738,
      BankName: "The Anjangaon Surji Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1739,
      BankName: "The Aurangabad District Industrial & Urban Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1740,
      BankName: "The Bhagyalakshmi Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1741,
      BankName: "The Bhandara  Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1742,
      BankName: "The Chikhli Urban Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1743,
      BankName: "The Chitnavispura Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1744,
      BankName: "The Dr. Panjabrao Deshmukh Urban Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 1745,
      BankName: "The Ghadchiroli Nagari Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1746,
      BankName: "The Jalna People's Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1747,
      BankName: "The Latur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1748,
      BankName: "The Mahila Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1749,
      BankName: "The Mehkar Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1750,
      BankName: "The Nanded Merchant's Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1751,
      BankName: "The Nandura Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1752,
      BankName: "The Prerna Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1753,
      BankName: "The Sahyog Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1754,
      BankName: "The Udgir Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1755,
      BankName: "The Vaidyanath Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1756,
      BankName: "The Vaijapur Merchants Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1757,
      BankName: "The Washim Urban Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1758,
      BankName: "The Yavatmal Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1759,
      BankName: "Tirupati Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1760,
      BankName: "Umiya Urban Co-operative Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1761,
      BankName: "Urban Coop. Bank Ltd. Dharangaon.",
      ParentCode: 0,
    },
    {
      BankCode: 1762,
      BankName: "Vaijanath Appa Saraf Marathwada Nagari",
      ParentCode: 0,
    },
    {
      BankCode: 1763,
      BankName: "Vaishya Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1764,
      BankName: "Valmiki Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1765,
      BankName: "Vani Merchants Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1766,
      BankName: "Vasundhara Mahila Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1767,
      BankName: "Vidharbha Merchants Urban Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1768,
      BankName: "Vidyanand Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1769,
      BankName: "Vikas Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1770,
      BankName: "Vikas Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1771,
      BankName: "Vishwas Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1772,
      BankName: "Vyapari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1773,
      BankName: "Wana Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1774,
      BankName: "Wani Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1775,
      BankName: "Wardha District Ashirwad Mahila Nagari Sahakari",
      ParentCode: 0,
    },
    {
      BankCode: 1776,
      BankName: "Wardha Nagari Sahakari Adhikosh (Bank)",
      ParentCode: 0,
    },
    {
      BankCode: 1777,
      BankName: "Wardha Zilla Parishad Employees (Urban) Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1778,
      BankName: "Wardhaman Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 847,
      BankName: "Shri Shadakshari Shivayogi Siddharameshwar",
      ParentCode: 0,
    },
    {
      BankCode: 848,
      BankName: "Shri Shantappanna Mirji Urban Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 849,
      BankName: "Shri Sharan Veereshwar Sahakari Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 850,
      BankName: "Shri Shiddheshwar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 851,
      BankName: "Shri Shivayogi Murughendra Swami Urban Co-",
      ParentCode: 0,
    },
    {
      BankCode: 852,
      BankName: "Shri Siddeshwar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 853,
      BankName: "Shri Veer Pulikeshi Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 854,
      BankName: "Shri Vijay Mahantesh Co-operative  Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 855,
      BankName: "Siddaganga Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 856,
      BankName: "Sindgi Urban Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 857,
      BankName: "Sir M Vishweshwaraiah Sahakar Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 858,
      BankName: "Sir M.Visvesvaraya Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 859,
      BankName: "Sirsi Urban Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 860,
      BankName: "Soubhagya Mahila Souharda Sahakar Bank",
      ParentCode: 0,
    },
    {
      BankCode: 861,
      BankName: "South Kanara Government Officers' Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 862,
      BankName: "Sree Anjaneya Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 863,
      BankName: "Sree Bhyraveshwara Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 864,
      BankName: "Sree Charan Souharda Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 865,
      BankName: "Sree Harihareshwara Urban Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 866,
      BankName: "Sree Subramanyeswara Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 867,
      BankName: "Sree Thyagaraja Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 868,
      BankName: "Sreenidhi Souharda Sahakari Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 869,
      BankName: "Sri Amba Bhavani Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 870,
      BankName: "Sri Banashankari Mahila Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 871,
      BankName: "Sri Basaveshwar Pattana Sahakari Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 872,
      BankName: "Sri Basaveshwar Sahakar Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 873,
      BankName: "Sri Basaveshwara Pattana Sahakara Bank",
      ParentCode: 0,
    },
    {
      BankCode: 874,
      BankName: "Sri Basaveswar Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 875,
      BankName: "Sri Bhagavathi Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 876,
      BankName: "Sri Channabasavaswamy Souharda Pattana",
      ParentCode: 0,
    },
    {
      BankCode: 877,
      BankName: "Sri Durgadevi Mahila Sahakari Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 878,
      BankName: "Sri Ganapathi Urban Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 879,
      BankName: "Sri Ganesh Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 880,
      BankName: "Sri Gavisiddeshwar Urban Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 881,
      BankName: "Sri Gokarnanath Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 882,
      BankName: "Sri Guru Raghavendra Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 883,
      BankName: "Sri Kalidasa Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 884,
      BankName: "Sri Kannikaparameshwari Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 885,
      BankName: "Sri Kannikaparameswari Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 886,
      BankName: "Sri Kanyakaparameswari Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 887,
      BankName: "Sri Krishnarajendra Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 888,
      BankName: "Sri Lakshmi Mahila Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 889,
      BankName: "Sri Lakshminarayana Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 890,
      BankName: "Sri Mahatma Basaveshwar Co-Operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 891,
      BankName: "Sri Parshwanatha Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 892,
      BankName: "Sri Rama Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 893,
      BankName: "Sri Revana Siddeshwar Pattana Sahakara Bank",
      ParentCode: 0,
    },
    {
      BankCode: 894,
      BankName: "Sri Seetharaghava Souharda Sahakara Bank",
      ParentCode: 0,
    },
    {
      BankCode: 895,
      BankName: "Sri Siddarameshwara Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 896,
      BankName: "Sri Sudha Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 897,
      BankName: "Sri Vasavamba Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 898,
      BankName: "Sri Veerabhadreshwar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 899,
      BankName: "Srimatha Mahila Sahakari Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 900,
      BankName: "Sriramnagar Pattana Sahakar Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 901,
      BankName: "SUCO Souharda Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 902,
      BankName: "Suvarna Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1779,
      BankName: "Warud Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1780,
      BankName: "Yashwant Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1781,
      BankName: "Yeola Merchants Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1782,
      BankName: "Yeshwant Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1783,
      BankName: "Bicholim Urban Co- operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1784,
      BankName: "Citizen Co-op. Bank Ltd. Vasco-De-Gama.",
      ParentCode: 0,
    },
    {
      BankCode: 1785,
      BankName: "Women's Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1786,
      BankName: "The Bihar Awami Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1787,
      BankName: "The Tapindu Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1788,
      BankName: "The V.S.V.Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1789,
      BankName: "Bhilai Nagarik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1790,
      BankName: "Bilaspur Nagarik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1791,
      BankName: "Laxmi Mahila Nagarik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1792,
      BankName: "Mahila Nagrik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1793,
      BankName: "Nagarik Sahakari Bank Maryadit. Raipur.",
      ParentCode: 0,
    },
    {
      BankCode: 1794,
      BankName: "Pragati Mahila Nagarik sahakari Bank Ltd.Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1795,
      BankName: "Raigarh Nagarik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1796,
      BankName: "Renuka Nagarik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1797,
      BankName: "The Raipur Urban Mercantile Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1798,
      BankName: "Vyavsaik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1799,
      BankName: "The Jamshedpur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1800,
      BankName: "The Koylanchal Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1801,
      BankName: "The  Chamba Urban Coop.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1802,
      BankName: "The Baghat Urban Coop Bank Limited Solan.",
      ParentCode: 0,
    },
    {
      BankCode: 1803,
      BankName: "The Mandi Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1804,
      BankName: "The Parwanoo Urban Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1805,
      BankName: "The Shimla Urban Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1806,
      BankName: "Adhyapaka Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1807,
      BankName: "Alleppey Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1808,
      BankName: "Alwaye Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1809,
      BankName: "Balusseri Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1810,
      BankName: "Calicut Co-operative Urban Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1811,
      BankName: "Changanacherry Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1812,
      BankName: "Cherpalcheri Co-operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1813,
      BankName: "Coastal Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1814,
      BankName: "Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1815,
      BankName: "Feroke Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1816,
      BankName: "Guruvayur Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1817,
      BankName: "Irinjalakuda Town Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1818,
      BankName: "Kaduthuruthy Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1819,
      BankName: "Karamana Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1820,
      BankName: "Karunagapalli Taluk Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1821,
      BankName: "Kasargod Co-operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1822,
      BankName: "Kattappana Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1823,
      BankName: "Kerala Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1824,
      BankName: "Kottakal Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1825,
      BankName: "Kottayam Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1826,
      BankName: "Kuttiady Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1827,
      BankName: "L.I.C. of India Staff Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1828,
      BankName: "Manjeri Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1829,
      BankName: "Mattancherry Mahajanik Co-operative Urban Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1830,
      BankName: "Mattancherry Sarvajanik Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1831,
      BankName: "Meenachil East Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1832,
      BankName: "Muvattupuzha Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1833,
      BankName: "Nadapuram Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1834,
      BankName: "Nedumangad Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1835,
      BankName: "Nemmara Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1,
      BankName: "Axis Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 2,
      BankName: "Bandhan Bank",
      ParentCode: 0,
    },
    {
      BankCode: 3,
      BankName: "Csb Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 4,
      BankName: "City Union Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 5,
      BankName: "Dcb Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 6,
      BankName: "Dhanlaxmi Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 7,
      BankName: "Federal Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 8,
      BankName: "Hdfc Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 9,
      BankName: "Icici Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 10,
      BankName: "Indusind Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 11,
      BankName: "Idfc First Bank",
      ParentCode: 0,
    },
    {
      BankCode: 12,
      BankName: "Jammu & Kashmir Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 13,
      BankName: "Karnataka Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 14,
      BankName: "Karur Vysya Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 15,
      BankName: "Kotak Mahindra Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 16,
      BankName: "Nainital Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 17,
      BankName: "Rbl Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 18,
      BankName: "South Indian Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 19,
      BankName: "Tamilnad Mercantile Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 20,
      BankName: "Yes Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 21,
      BankName: "Idbi Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 22,
      BankName: "Coastal Local Area Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 23,
      BankName: "Krishna Bhima Samruddhi Local Area Bank",
      ParentCode: 0,
    },
    {
      BankCode: 24,
      BankName: "Au Small Finance Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 25,
      BankName: "Capital Small Finance Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 26,
      BankName: "Equitas Small Finance Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 27,
      BankName: "Esaf Small Finance Bank",
      ParentCode: 0,
    },
    {
      BankCode: 28,
      BankName: "Suryoday Small Finance Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 29,
      BankName: "Ujjivan Small Finance Bank",
      ParentCode: 0,
    },
    {
      BankCode: 30,
      BankName: "Utkarsh Small Finance Bank",
      ParentCode: 0,
    },
    {
      BankCode: 31,
      BankName: "North East Small Finance Bank",
      ParentCode: 0,
    },
    {
      BankCode: 32,
      BankName: "Jana Small Finance Bank",
      ParentCode: 0,
    },
    {
      BankCode: 33,
      BankName: "Shivalik Small Finance Bank",
      ParentCode: 0,
    },
    {
      BankCode: 34,
      BankName: "Unity Small Finance Bank",
      ParentCode: 0,
    },
    {
      BankCode: 35,
      BankName: "Airtel Payments Bank",
      ParentCode: 0,
    },
    {
      BankCode: 36,
      BankName: "India Post",
      ParentCode: 0,
    },
    {
      BankCode: 37,
      BankName: "Fino Payments Bank",
      ParentCode: 0,
    },
    {
      BankCode: 38,
      BankName: "Paytm Payments Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 39,
      BankName: "Jio Payments Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 40,
      BankName: "Nsdl Payments Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 41,
      BankName: "Bank Of Baroda",
      ParentCode: 0,
    },
    {
      BankCode: 42,
      BankName: "Bank Of India",
      ParentCode: 0,
    },
    {
      BankCode: 43,
      BankName: "Bank Of Maharashtra",
      ParentCode: 0,
    },
    {
      BankCode: 44,
      BankName: "Canara Bank",
      ParentCode: 0,
    },
    {
      BankCode: 45,
      BankName: "Central Bank Of India",
      ParentCode: 0,
    },
    {
      BankCode: 46,
      BankName: "Indian Bank",
      ParentCode: 0,
    },
    {
      BankCode: 47,
      BankName: "Indian Overseas Bank",
      ParentCode: 0,
    },
    {
      BankCode: 48,
      BankName: "Punjab & Sind Bank",
      ParentCode: 0,
    },
    {
      BankCode: 49,
      BankName: "Punjab National Bank",
      ParentCode: 0,
    },
    {
      BankCode: 50,
      BankName: "State Bank Of India",
      ParentCode: 0,
    },
    {
      BankCode: 51,
      BankName: "Uco Bank",
      ParentCode: 0,
    },
    {
      BankCode: 52,
      BankName: "Union Bank Of India",
      ParentCode: 0,
    },
    {
      BankCode: 53,
      BankName: "National Bank For Agriculture & Rural Development",
      ParentCode: 0,
    },
    {
      BankCode: 54,
      BankName: "Export   Import Bank Of India",
      ParentCode: 0,
    },
    {
      BankCode: 55,
      BankName: "National Housing Bank",
      ParentCode: 0,
    },
    {
      BankCode: 56,
      BankName: "Small Industries Development Bank Of India",
      ParentCode: 0,
    },
    {
      BankCode: 57,
      BankName: "Assam Gramin Vikash Bank",
      ParentCode: 0,
    },
    {
      BankCode: 58,
      BankName: "Andhra Pradesh Grameena Vikas Bank",
      ParentCode: 0,
    },
    {
      BankCode: 59,
      BankName: "Andhra Pragathi Grameena Bank",
      ParentCode: 0,
    },
    {
      BankCode: 60,
      BankName: "Arunachal Pradesh Rural Bank",
      ParentCode: 0,
    },
    {
      BankCode: 61,
      BankName: "Aryavart Bank",
      ParentCode: 0,
    },
    {
      BankCode: 62,
      BankName: "Bangiya Gramin Vikash Bank",
      ParentCode: 0,
    },
    {
      BankCode: 63,
      BankName: "Baroda Gujarat Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 64,
      BankName: "Baroda Rajasthan Kshetriya Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 65,
      BankName: "Baroda Up Bank",
      ParentCode: 0,
    },
    {
      BankCode: 66,
      BankName: "Chaitanya Godavari Grameena Bank",
      ParentCode: 0,
    },
    {
      BankCode: 67,
      BankName: "Chhattisgarh Rajya Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 68,
      BankName: "Dakshin Bihar Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 69,
      BankName: "Ellaquai Dehati Bank",
      ParentCode: 0,
    },
    {
      BankCode: 70,
      BankName: "Himachal Pradesh Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 71,
      BankName: "J And K Grameen Bank",
      ParentCode: 0,
    },
    {
      BankCode: 72,
      BankName: "Jharkhand Rajya Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 73,
      BankName: "Karnataka Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 74,
      BankName: "Karnataka Vikas Grameena Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 75,
      BankName: "Kerala Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 76,
      BankName: "Madhya Pradesh Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 77,
      BankName: "Madhyanchal Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 78,
      BankName: "Maharashtra Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 79,
      BankName: "Manipur Rural Bank",
      ParentCode: 0,
    },
    {
      BankCode: 80,
      BankName: "Meghalaya Rural Bank",
      ParentCode: 0,
    },
    {
      BankCode: 903,
      BankName: "Swami Vivekanand Sahakari Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 904,
      BankName: "Swarna Bharathi Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 905,
      BankName: "Talikoti Sahakari Bank Niyamit.Talikoti",
      ParentCode: 0,
    },
    {
      BankCode: 906,
      BankName: "Tavaragera Pattana Souharda Sahakar Bank",
      ParentCode: 0,
    },
    {
      BankCode: 907,
      BankName: "Teachers' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 908,
      BankName: "Textile Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 909,
      BankName: "Textile Manufacturers' Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 910,
      BankName: "The Citizen Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 911,
      BankName: "Town Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 912,
      BankName: "Tumkur Grain Merchants Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 913,
      BankName: "Tumkur Veerashaiva Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 914,
      BankName: "Udupi Co-operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 915,
      BankName: "Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 916,
      BankName: "V.I.S.L. Employees' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 917,
      BankName: "Veershaiva Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 918,
      BankName: "Vijaypur Sahakari Bank Niyamit ",
      ParentCode: 0,
    },
    {
      BankCode: 919,
      BankName: "Vikas Souharda Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 920,
      BankName: "Vikas Urban Co-operative Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 921,
      BankName: "Virajpet Pattana Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 922,
      BankName: "Vishwakalyan Sahakara Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 923,
      BankName: "Vishwakarma Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 924,
      BankName: "Vysya Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 925,
      BankName: "Yaragatti Urban  Co-operative Credit Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 926,
      BankName: "Anandeshwari Nagrik Sahakarti bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 927,
      BankName: "Arihant Urban Co-Operative Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 928,
      BankName: "Astha Mahila Nagrik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 929,
      BankName: "Basoda Nagrik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 930,
      BankName: "Betul Nagarik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 931,
      BankName: "Bhind Nagarik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 932,
      BankName: "Dr.Ambedkar Nagarik Sah.Bk.Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 933,
      BankName: "Guna Nagarik sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 934,
      BankName: "Indore Cloth Market Coop. Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 935,
      BankName: "Indore Paraspar Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 936,
      BankName: "Indore Swayamsidha Mahila Co-Operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 937,
      BankName: "Jabalpur Mahila Nagarik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 938,
      BankName: "Jharneswar Nagarik Sah Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 939,
      BankName: "Khargon Nagrik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 940,
      BankName: "Krishna Mercantile Co-Operative Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 941,
      BankName: "Lakshmibai Mahila Nagarik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 942,
      BankName: "Maa Sharda Mahila Nagrik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 943,
      BankName: "Mahanagar Nagrik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 944,
      BankName: "Mahismati Nagrik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 945,
      BankName: "Nagarik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 946,
      BankName: "Nagarik Sahakari Bank maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 947,
      BankName: "Nagarik Sahakari Bank Maryadit.Shivpuri.",
      ParentCode: 0,
    },
    {
      BankCode: 948,
      BankName: "Nagrik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 949,
      BankName: "Paraspar Sahayak Coop Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 950,
      BankName: "Raj Rajeshwari Mahila Nagrik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 951,
      BankName: "Sadbhav Nagarik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 952,
      BankName: "Sadguru Nagari Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 953,
      BankName: "Samata Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 954,
      BankName: "Sanawad Nagrik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 955,
      BankName: "Sardar Singh Nagarik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 956,
      BankName: "Sehore Nagrik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 957,
      BankName: "Shajapur Nagrik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 958,
      BankName: "Shramik Nagrik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 959,
      BankName: "Shri Balaji  Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 960,
      BankName: "Shri Satya Sai Nagarik Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1836,
      BankName: "Neyyattinkara Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1837,
      BankName: "Nilambur Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1838,
      BankName: "Nileshwar Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1839,
      BankName: "Ottapalam Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1840,
      BankName: "Pala Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1841,
      BankName: "Palghat Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1842,
      BankName: "Payangadi Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1843,
      BankName: "Payyanur Co-operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1844,
      BankName: "Payyoli Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1845,
      BankName: "People's Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1846,
      BankName: "Ponani Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1847,
      BankName: "Quilon Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1848,
      BankName: "Shoranur Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1849,
      BankName: "Sultan's Battery Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1850,
      BankName: "Taliparamba Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1851,
      BankName: "Tanur Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1852,
      BankName: "Tellicherry Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1853,
      BankName: "The Badagara Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1854,
      BankName: "The Kannur Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1855,
      BankName: "The Kodungallur Town Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1856,
      BankName: "Thodupuzha Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1857,
      BankName: "Tirur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1858,
      BankName: "Tiruvalla East Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1859,
      BankName: "Tiruvalla Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1860,
      BankName: "Trichur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1861,
      BankName: "Trivandrum Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1862,
      BankName: "Vaikom Urban Co-operative Bank LImited.",
      ParentCode: 0,
    },
    {
      BankCode: 1863,
      BankName: "Ahmedabad Mercantile Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1864,
      BankName: "Kalupur Commercial Coop.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1865,
      BankName: "Mehsana Urban Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1866,
      BankName: "Nutan Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1867,
      BankName: "Rajkot Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1868,
      BankName: "SBPP Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1869,
      BankName: "Surat Peoples Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1870,
      BankName: "Amanath Co-operative   Bank Ltd. Bangalore",
      ParentCode: 0,
    },
    {
      BankCode: 1871,
      BankName: "Andhra Pradesh Mahesh Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1872,
      BankName: "Indian Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1873,
      BankName: "Abhyudaya Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1874,
      BankName: "Apna Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1875,
      BankName: "Bassein Catholic Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1876,
      BankName: "Bharat Co-operative Bank (Mumbai) Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1877,
      BankName: "Bharati Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1878,
      BankName: "Bombay Mercantile Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1879,
      BankName: "Citizen Credit Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1880,
      BankName: "Cosmos Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1881,
      BankName: "Dombivli Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1882,
      BankName: "G. P. Parsik Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1883,
      BankName: "Greater Bombay Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1884,
      BankName: "GS Mahanagar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1885,
      BankName: "Jalgaon Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1886,
      BankName: "Jalgaon People's Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1887,
      BankName: "Janakalyan Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1888,
      BankName: "Janalaxmi Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1889,
      BankName: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1890,
      BankName: "Kalyan Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1891,
      BankName: "Karad Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1892,
      BankName: "Nasik Merchant's Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1893,
      BankName: "New India Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1894,
      BankName: "NKGSB Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 81,
      BankName: "Mizoram Rural Bank",
      ParentCode: 0,
    },
    {
      BankCode: 82,
      BankName: "Nagaland Rural Bank",
      ParentCode: 0,
    },
    {
      BankCode: 83,
      BankName: "Odisha Gramya Bank",
      ParentCode: 0,
    },
    {
      BankCode: 84,
      BankName: "Paschim Banga Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 85,
      BankName: "Prathama Up Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 86,
      BankName: "Puduvai Bharathiar Grama Bank",
      ParentCode: 0,
    },
    {
      BankCode: 87,
      BankName: "Punjab Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 88,
      BankName: "Rajasthan Marudhara Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 89,
      BankName: "Saptagiri Grameena Bank",
      ParentCode: 0,
    },
    {
      BankCode: 90,
      BankName: "Sarva Haryana Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 91,
      BankName: "Saurashtra Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 92,
      BankName: "Tamil Nadu Grama Bank",
      ParentCode: 0,
    },
    {
      BankCode: 93,
      BankName: "Telangana Grameena Bank",
      ParentCode: 0,
    },
    {
      BankCode: 94,
      BankName: "Tripura Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 95,
      BankName: "Uttar Bihar Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 96,
      BankName: "Utkal Grameen Bank",
      ParentCode: 0,
    },
    {
      BankCode: 97,
      BankName: "Uttarbanga Kshetriya Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 98,
      BankName: "Vidharbha Konkan Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 99,
      BankName: "Uttarakhand Gramin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 100,
      BankName: "Ab Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 101,
      BankName: "American Express Banking Corporation",
      ParentCode: 0,
    },
    {
      BankCode: 102,
      BankName: "Australia And New Zealand Banking Group Limited",
      ParentCode: 0,
    },
    {
      BankCode: 103,
      BankName: "Barclays Bank Plc",
      ParentCode: 0,
    },
    {
      BankCode: 104,
      BankName: "Bank Of America",
      ParentCode: 0,
    },
    {
      BankCode: 105,
      BankName: "Bank Of Bahrain & Kuwait B.S.C.",
      ParentCode: 0,
    },
    {
      BankCode: 106,
      BankName: "Bank Of Ceylon",
      ParentCode: 0,
    },
    {
      BankCode: 107,
      BankName: "Bank Of China Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 108,
      BankName: "Bank Of Nova Scotia",
      ParentCode: 0,
    },
    {
      BankCode: 109,
      BankName: "Bnp Paribas",
      ParentCode: 0,
    },
    {
      BankCode: 110,
      BankName: "Citibank N.A.",
      ParentCode: 0,
    },
    {
      BankCode: 111,
      BankName:
        "Cooperatieve Rabobank U.A./ Coöperatieve Centrale Raiffeisen-Boerenleenbank B.A.",
      ParentCode: 0,
    },
    {
      BankCode: 112,
      BankName: "Credit Agricole Corporate And Investment Bank",
      ParentCode: 0,
    },
    {
      BankCode: 113,
      BankName: "Ctbc Bank Co., Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 114,
      BankName: "Dbs Bank India Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 115,
      BankName: "Deutsche Bank",
      ParentCode: 0,
    },
    {
      BankCode: 116,
      BankName: "Doha Bank",
      ParentCode: 0,
    },
    {
      BankCode: 117,
      BankName: "Emirates Nbd Bank P.J.S.C",
      ParentCode: 0,
    },
    {
      BankCode: 118,
      BankName: "First Abu Dhabi Bank Pjsc",
      ParentCode: 0,
    },
    {
      BankCode: 119,
      BankName: "Firstrand Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 120,
      BankName: "Hong Kong And Shanghai Banking Corporation Limited",
      ParentCode: 0,
    },
    {
      BankCode: 121,
      BankName: "Industrial And Commercial Bank Of China Limited",
      ParentCode: 0,
    },
    {
      BankCode: 122,
      BankName: "Industrial Bank Of Korea",
      ParentCode: 0,
    },
    {
      BankCode: 123,
      BankName: "Jpmorgan Chase Bank",
      ParentCode: 0,
    },
    {
      BankCode: 124,
      BankName: "Jsc Vtb Bank",
      ParentCode: 0,
    },
    {
      BankCode: 125,
      BankName: "Keb Hana Bank",
      ParentCode: 0,
    },
    {
      BankCode: 126,
      BankName: "Kookmin Bank",
      ParentCode: 0,
    },
    {
      BankCode: 127,
      BankName: "Mashreq Bank Psc",
      ParentCode: 0,
    },
    {
      BankCode: 128,
      BankName: "Mizuho Bank",
      ParentCode: 0,
    },
    {
      BankCode: 129,
      BankName: "Mufg Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 130,
      BankName: "Nong Hyup Bank",
      ParentCode: 0,
    },
    {
      BankCode: 131,
      BankName: "Natwest Markets Plc",
      ParentCode: 0,
    },
    {
      BankCode: 132,
      BankName: "Pt Bank Maybank Indonesia Tbk",
      ParentCode: 0,
    },
    {
      BankCode: 133,
      BankName: "Qatar National Bank",
      ParentCode: 0,
    },
    {
      BankCode: 134,
      BankName: "Sberbank",
      ParentCode: 0,
    },
    {
      BankCode: 135,
      BankName: "Sbm Bank India Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 136,
      BankName: "Shinhan Bank",
      ParentCode: 0,
    },
    {
      BankCode: 137,
      BankName: "Societe Generale",
      ParentCode: 0,
    },
    {
      BankCode: 138,
      BankName: "Sonali Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 139,
      BankName: "Standard Chartered Bank",
      ParentCode: 0,
    },
    {
      BankCode: 140,
      BankName: "Sumitomo Mitsui Banking Corporation",
      ParentCode: 0,
    },
    {
      BankCode: 141,
      BankName: "Ubs Ag",
      ParentCode: 0,
    },
    {
      BankCode: 142,
      BankName: "United Overseas Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 143,
      BankName: "Woori Bank",
      ParentCode: 0,
    },
    {
      BankCode: 144,
      BankName: "The Andhra Pradesh State Co-operative Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 145,
      BankName: "The Bihar State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 146,
      BankName: "The Chhatisgarh Rajya Sahakari Bank Maryadit ",
      ParentCode: 0,
    },
    {
      BankCode: 147,
      BankName: "The Goa State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 148,
      BankName: "Gujarat State Co-operative Bank Ltd..  ",
      ParentCode: 0,
    },
    {
      BankCode: 149,
      BankName: "The Haryana State Co-operative Apex Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 150,
      BankName: "The Himachal Pradesh State Co-operative Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 151,
      BankName: "The Karnataka State Co-operative Apex Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 152,
      BankName: "The Kerala State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 153,
      BankName: "The Madhya Pradesh Rajya Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 154,
      BankName: "The Maharashtra State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 155,
      BankName: "The Odisha State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 156,
      BankName: "The Puducherry State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 961,
      BankName: "Shri Vaibhav Lakshmi Mahila Nagarik Sahakari",
      ParentCode: 0,
    },
    {
      BankCode: 962,
      BankName: "Shubhalaxmi Mahila Coop Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 963,
      BankName: "Smriti Nagrik Sahakari Bank  Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 964,
      BankName: "The Nagarik Shakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 965,
      BankName: "Transport Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 966,
      BankName: "Ujjain Audhyogik Vikas Nagrik Sahkari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 967,
      BankName: "Ujjain Nagarik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 968,
      BankName: "Ujjain Paraspar Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 969,
      BankName: "Vikramaditya Nagarik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 970,
      BankName: "Vivekanada Nagarik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 971,
      BankName: "Vyaparik Audhyogik Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 972,
      BankName: "Vyavasayik Evam Audhyogik Sah.",
      ParentCode: 0,
    },
    {
      BankCode: 973,
      BankName: "Balasore Coop.Urban Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 974,
      BankName: "Berhampur Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 975,
      BankName: "Cooperative  Urban  Bank  Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 976,
      BankName: "Jeypore coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 977,
      BankName: "Kendrapara Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 978,
      BankName: "Puri Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 979,
      BankName: "Urban Co op Bank Limited Cuttack.",
      ParentCode: 0,
    },
    {
      BankCode: 980,
      BankName: "Urban Coop Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 981,
      BankName: "Utkal Coop Banking Soc Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 982,
      BankName: "Hissar Urban Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 983,
      BankName: "Imperial Urban cooprative Bank Ltd.Jalandhar",
      ParentCode: 0,
    },
    {
      BankCode: 984,
      BankName: "The Citizens Urban Co op Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 985,
      BankName: "Hindu Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 986,
      BankName: "The Karan Urban Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 987,
      BankName: "The Kurukshetra Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 988,
      BankName: "The Nakodar Hindu Urban Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 989,
      BankName: "The Panchkula Urban Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 990,
      BankName: "The Panipat Urban Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 991,
      BankName: "The Postal & R.M.S.Employees' Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 992,
      BankName: "The Sonepat urban Co-op. Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 993,
      BankName: "Abiramam Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 994,
      BankName: "Ambasamudram Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 995,
      BankName: "Ammapet Urban Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 996,
      BankName: "Arantangi Co-Op Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 997,
      BankName: "Arcot Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 998,
      BankName: "Ariyalur Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 999,
      BankName: "Arkonam Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1000,
      BankName: "Arni Co-Operative Town  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1001,
      BankName: "Attur Town Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1002,
      BankName: "B.Komarapalayam Co op Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1003,
      BankName: "Batlagundu Co op Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1004,
      BankName: "Bharat Heavy Electricals Employees Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1005,
      BankName: "Bhavanikudal Co op Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1006,
      BankName: "Bhupathiraju Co op Credit Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1007,
      BankName: "Big Kancheepuram Co op Town Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1008,
      BankName: "Chengalpattu Coop.Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1009,
      BankName: "Chennai Port Trust Employees Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1010,
      BankName: "Chennimalai Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1011,
      BankName: "Chidambaram Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1012,
      BankName: "Coimbatore City Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1013,
      BankName: "Coonoor Cooperative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1014,
      BankName: "Cordite Factory Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1015,
      BankName: "Cuddalore & Villipuram DCCB Employees Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1016,
      BankName: "Dalmiapuram Empl.s'Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1017,
      BankName: "Dharapuram Coop Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1018,
      BankName: "Dharmapuri Co-op. Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1019,
      BankName: "Dindigul Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1895,
      BankName: "Pravara Sahakari  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1896,
      BankName: "Rajarambapu Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1897,
      BankName: "Sangli Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1898,
      BankName: "Saraswat Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1899,
      BankName: "Shamrao Vithal Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1900,
      BankName: "Solapur Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1901,
      BankName: "Thane Bharat Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1902,
      BankName: "TJSB Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1903,
      BankName: "Vasai Vikas Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1904,
      BankName: "Zoroastrian Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1905,
      BankName: "Nagpur Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1906,
      BankName: "Shikshak Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1907,
      BankName: "The Akola Janata Commercial Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1908,
      BankName: "The Akola Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1909,
      BankName: "The Khamgaon Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1910,
      BankName: "Goa Urban Co-operative Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 157,
      BankName: "The Punjab State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 158,
      BankName: "The Rajasthan State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 159,
      BankName: "The Tamil Nadu State Apex Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 160,
      BankName: "The Telangana State Cooperative Apex Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 161,
      BankName: "The Uttar Pradesh Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 162,
      BankName: "The Uttarakhand State Co-operative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 163,
      BankName: "The West Bengal State Co-operative Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 164,
      BankName: "Tripura State Co-operative Bank Ltd..  ",
      ParentCode: 0,
    },
    {
      BankCode: 165,
      BankName: "The Delhi State Cooperative Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 166,
      BankName: "The Meghalaya Co-operative Apex Bank Ltd.. ",
      ParentCode: 0,
    },
    {
      BankCode: 167,
      BankName: "Sikkim State Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 168,
      BankName: "The Andaman and Nicobar State Co-operative Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 169,
      BankName: "The Arunachal Pradesh State Co-operative Apex Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 170,
      BankName: "The Assam Co-operative Apex Bank Ltd..  ",
      ParentCode: 0,
    },
    {
      BankCode: 171,
      BankName: "The Chandigarh State Co-operative Bank Ltd..  ",
      ParentCode: 0,
    },
    {
      BankCode: 172,
      BankName: "The Jammu and Kashmir State Co-operative Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 173,
      BankName: "Jharkhand State Cooperative Bank Ltd..   ",
      ParentCode: 0,
    },
    {
      BankCode: 174,
      BankName: "The Manipur State Co-operative Bank Ltd..  ",
      ParentCode: 0,
    },
    {
      BankCode: 175,
      BankName: "The Mizoram Co-operative Apex Bank Ltd..  ",
      ParentCode: 0,
    },
    {
      BankCode: 176,
      BankName: "The Nagaland State Co-operative Bank Ltd..  ",
      ParentCode: 0,
    },
    {
      BankCode: 177,
      BankName: "The Daman & Diu State Co-operative Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 178,
      BankName: "The Adilabad District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 179,
      BankName: "The Anantapur District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 180,
      BankName: "Chittoor District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 181,
      BankName: "Cuddapah District Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 182,
      BankName: "Eluru District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 183,
      BankName: "Guntur District Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 184,
      BankName: "The Hyderabad District Cooperative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 185,
      BankName: "Kakinada Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 186,
      BankName: "Karimnagar District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 187,
      BankName: "The District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 188,
      BankName: "Krishna District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 189,
      BankName: "The Kurnool District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 190,
      BankName: "Medak District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 191,
      BankName: "The Nalgonda District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 192,
      BankName: "Nellore District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 193,
      BankName: "Nizamabad District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 194,
      BankName: "Prakasam District Co-operative Central Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 195,
      BankName: "The District Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 196,
      BankName: "Vishakapatnam District Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 197,
      BankName: "The Warangal District Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 198,
      BankName: "Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 199,
      BankName: "The Aurangabad District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 200,
      BankName: "The Begusarai District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 201,
      BankName: "The National Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 202,
      BankName: "The Bhagalpur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 203,
      BankName: "The Gopalganj District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 204,
      BankName: "The Katihar District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 205,
      BankName: "The Khagaria District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 206,
      BankName: "Vaishali District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 207,
      BankName: "The Magadh Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 208,
      BankName: "Motihari District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 209,
      BankName: "Monghyr-Jamui Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 210,
      BankName: "Muzaffarpur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 211,
      BankName: "Nawadh Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 212,
      BankName: "Nalanda District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1020,
      BankName: "Erode Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1021,
      BankName: "George Town Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1022,
      BankName: "Gobichettipalyam Co op Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1023,
      BankName: "Gudiyattam Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1024,
      BankName: "Ilayangudi Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1025,
      BankName: "Jolarpet Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1026,
      BankName: "Kallidaikurichi Coop Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1027,
      BankName: "Kamaraj coop Town Bank Ltd. Polur.",
      ParentCode: 0,
    },
    {
      BankCode: 1028,
      BankName: "Kamuthi Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1029,
      BankName: "Karaikudi Coop. Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1030,
      BankName: "Karuntattankudi Dravidian Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1031,
      BankName: "Karur Town Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1032,
      BankName: "Kaveripatnam Coop Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1033,
      BankName: "Kodaikanal Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1034,
      BankName: "Kovilpatti Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1035,
      BankName: "Krishnagiri Urban Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1036,
      BankName: "Kulitalai Coop Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1037,
      BankName: "Kumbakonam Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1038,
      BankName: "L.I.C.Employees' Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1039,
      BankName: "Lalgudi Coop Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1040,
      BankName: "Little Kancheepuram Coop Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1041,
      BankName: "Madura Sourashtra Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1042,
      BankName: "Madurantakam Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1043,
      BankName: "Manapparai Town Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1044,
      BankName: "Mannargudi Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1045,
      BankName: "Mayuram Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1046,
      BankName: "Melur Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1047,
      BankName: "Mettupalayam Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1048,
      BankName: "Mukkuperi Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1049,
      BankName: "Namakkal Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1050,
      BankName: "Nazareth Urban Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1051,
      BankName: "Nellai Nagar Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1052,
      BankName: "Nicholson Coop. Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1053,
      BankName: "Omalur Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1054,
      BankName: "Palani Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1055,
      BankName: "Palayamkottai Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1056,
      BankName: "Pallikonda Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1057,
      BankName: "Papanasam Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1058,
      BankName: "Paramakudi Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1059,
      BankName: "Pattukottai Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1060,
      BankName: "Peoples Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1061,
      BankName: "Periyakulam Coop.Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1062,
      BankName: "Pollachi Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1063,
      BankName: "Pondicherry Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1064,
      BankName: "Prakasapuram Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1065,
      BankName: "Pudukottai Coop.Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1066,
      BankName: "Purasawalkam Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1067,
      BankName: "Rajapalayam Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1068,
      BankName: "Ramanathapuram Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1069,
      BankName: "Ranipet Town Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1070,
      BankName: "Rasipuram Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1071,
      BankName: "Reserve Bank Employees'Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1072,
      BankName: "Saidapet Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1073,
      BankName: "Salem Urban Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1074,
      BankName: "Sankari Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1075,
      BankName: "Satyamangalam Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1076,
      BankName: "Shevapet Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1077,
      BankName: "Sholavandan Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1078,
      BankName: "Sholinghur Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1079,
      BankName: "Sirkali Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 213,
      BankName: "Patliputra Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 214,
      BankName: "Rohika Central Co-opertive Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 215,
      BankName: " Samastipur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 216,
      BankName: " Purnea District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 217,
      BankName: "Siwan Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 218,
      BankName: "Sasaram Bhabua Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 219,
      BankName: "Sitamarhi Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 220,
      BankName: "District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 221,
      BankName: "Jilla Sahakari Kendriya Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 222,
      BankName: "The Ahmedabad District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 223,
      BankName: "The Amreli Jilla Madhyasth Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 224,
      BankName: "The Banaskantha District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 225,
      BankName: "The Baroda Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 226,
      BankName: "The Bhavnagar District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 227,
      BankName: "The Bharuch District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 228,
      BankName: "The Jamnagar District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 229,
      BankName: "The Junagadh Jilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 230,
      BankName: "The Kachchh District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 231,
      BankName: "The Kaira District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 232,
      BankName: "The Kodinar Taluka Co-operative Banking Union Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 233,
      BankName: "The Mehsana District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 234,
      BankName: "The Panchmahal District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 235,
      BankName: "The Sabarkantha District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 236,
      BankName: "The Rajkot District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 237,
      BankName: "The Surat District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 238,
      BankName: "The Surendranagar District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 239,
      BankName: "The Valsad District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 240,
      BankName: "Ambala Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 241,
      BankName: "Bhiwani Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 242,
      BankName: "Faridabad Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 243,
      BankName: "Fatehabad Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 244,
      BankName: "Gurgaon Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 245,
      BankName: "Hissar District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 246,
      BankName: "Jind Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 247,
      BankName: "Jhajjar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 248,
      BankName: ". Kaithal Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 249,
      BankName: "Karnal Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 250,
      BankName: "Kurukshetra Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 251,
      BankName: ". Mahendragarh Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 252,
      BankName: "Panchkula Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 253,
      BankName: "Panipat Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 254,
      BankName: "Rewari Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 255,
      BankName: ". Rohtak Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 256,
      BankName: "Sirsa Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 257,
      BankName: "Sonepat Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 258,
      BankName: "Yamuna Nagar Central Co-opertive Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 259,
      BankName: "Jogindra Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 260,
      BankName: "Kangra Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 261,
      BankName: "Anantnag Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 262,
      BankName: "Baramulla Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 263,
      BankName: "Jammu Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 264,
      BankName: "Dhanbad District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 265,
      BankName:
        "Bangalore District and Bangalore Rural District Co-op. Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 266,
      BankName: "Bagalkot Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 267,
      BankName: "Belgaum District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 268,
      BankName: "Bellary District Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 269,
      BankName: "Bidar District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1080,
      BankName: "Sivakasi Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1081,
      BankName: "Sri Kamalambika Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1082,
      BankName: "Sri Laxminarayana Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1083,
      BankName: "Srirangam Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1084,
      BankName: "Srivilliputtur Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1085,
      BankName: "Subramanianagar Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1086,
      BankName: "Tambaram Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1087,
      BankName: "Tamilnadu Circle Postal Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1088,
      BankName: "Thanjavur Public Servants'Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1089,
      BankName: "Thiruvaikuntam Co op Urban  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1090,
      BankName: "Thiruvalluvar Town Co-Op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1091,
      BankName: "Thyagarayanagar Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1092,
      BankName: "Tindivanam Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1093,
      BankName: "Tiruchendoor Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1094,
      BankName: "Tiruchengode Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1095,
      BankName: "Tiruchirapalli City Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1096,
      BankName: "Tiruchirapalli Hirudayapuram Co-Op Credit Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1097,
      BankName: "Tirukoilur Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1098,
      BankName: "Tirumangalam Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1099,
      BankName: "Tirunelveli Junction Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1100,
      BankName: "Tirupattur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1101,
      BankName: "Tiruppur Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1102,
      BankName: "Tiruturaipundi Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1103,
      BankName: "Tiruvallur Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1104,
      BankName: "Tiruvannamalai Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1105,
      BankName: "Tiruvathipuram Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1106,
      BankName: "Tuticorin Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1107,
      BankName: "Tuticorin Melur Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1108,
      BankName: "Udamalpet Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1109,
      BankName: "Udhagmandlam Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1110,
      BankName: "Usilampatti Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1111,
      BankName: "Uthamapalayam Coop. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1112,
      BankName: "Valparai Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1113,
      BankName: "Vaniyambadi Town Coop bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1114,
      BankName: "Varaganeri Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1115,
      BankName: "Vellala Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1116,
      BankName: "Vellore Coop Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1117,
      BankName: "Velur Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1118,
      BankName: "Villupuram Coop Urban Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1119,
      BankName: "Viravanallur Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1120,
      BankName: "Virudhunagar Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1121,
      BankName: "Vriddhachalam Coop Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1122,
      BankName: "Almora Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1123,
      BankName: "Kashipur Urban Co-opeerative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1124,
      BankName: "Kurmanchal Nagar Sahkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1125,
      BankName: "Uttarakhand co-op. Bank LTD. Riskikesh",
      ParentCode: 0,
    },
    {
      BankCode: 1126,
      BankName: "Delhi Nagrik Sehkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1127,
      BankName: "Indraprastha Sehkari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1128,
      BankName: "Innovative Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1129,
      BankName: "Jain Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1130,
      BankName: "Jamia Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1131,
      BankName: "National Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1132,
      BankName: "Ramgarhia Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1133,
      BankName: "The Citizen Co operative  Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1134,
      BankName: "The Janata Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1135,
      BankName: "The Kangra Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1136,
      BankName: "The Keshav Sehkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1137,
      BankName: "The Khattri Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1138,
      BankName: "The Vaish Co-operative Adarsh Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 270,
      BankName: "Vijayapura District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 271,
      BankName: "Chikmagalur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 272,
      BankName: "Chitradurg District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 273,
      BankName: "Davanagere Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 274,
      BankName: "Gulbarga District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 275,
      BankName: "Hassan District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 276,
      BankName: "Kanara District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 277,
      BankName: "Karnataka Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 278,
      BankName: "Kodagu District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 279,
      BankName: "Kolar District Central Co -opertive Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 280,
      BankName: "Mandya District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 281,
      BankName: "Mysore District central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 282,
      BankName: "Raichur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 283,
      BankName: "Shimoga District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 284,
      BankName: "South Canara District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 285,
      BankName: "Tumkur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 286,
      BankName: "Malappuram District Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 287,
      BankName: "Bhopal District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 288,
      BankName: "Guna District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 289,
      BankName: "Indore Premier Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 290,
      BankName: "Ahmednagar District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 291,
      BankName: "Akola District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 292,
      BankName: "Amravati District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 293,
      BankName: "Aurangabad District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 294,
      BankName: "Beed District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 295,
      BankName: "Bhandara District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 296,
      BankName: "Buldhana District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 297,
      BankName: "Chandrapur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 298,
      BankName: "Dhule & Nandurbar District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 299,
      BankName: "Solapur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 300,
      BankName: "Gadchiroli District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 301,
      BankName: "Jalna District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 302,
      BankName: "Jalgaon District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 303,
      BankName: "Kolhapur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 304,
      BankName: "Latur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 305,
      BankName: "Mumbai District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 306,
      BankName: "Nagpur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 307,
      BankName: "Nanded District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 308,
      BankName: "Nasik District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 309,
      BankName: "Osmanabad District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 310,
      BankName: "Parbhani District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 311,
      BankName: "Pune District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 312,
      BankName: "Raigad District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 313,
      BankName: "Ratnagiri District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 314,
      BankName: "Sangli District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 315,
      BankName: "Satara District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 316,
      BankName: "Sindhudurg District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 317,
      BankName: "Gondia District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 318,
      BankName: "Thane District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 319,
      BankName: "Wardha District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 320,
      BankName: "Yavatmal District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 321,
      BankName: "Angul United Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 322,
      BankName: "Aska Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 323,
      BankName: "Bolangir District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 324,
      BankName: "Balasore Bhadrak District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1139,
      BankName: "The Vaish Co-operative New Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1140,
      BankName: "Citizens' Urban Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1141,
      BankName: "Agartala Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1142,
      BankName: "Cooperative City Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1143,
      BankName: "Gauhati Co-op. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1144,
      BankName: "Imphal Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1145,
      BankName: "Industrial Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1146,
      BankName: "Jowai Co-Operative Urban Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1147,
      BankName: "Konoklota Mahila Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1148,
      BankName: "Mahabhairab Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1149,
      BankName: "Mangaldai Nagar Samabai Bank Ltd.Mangaldai.",
      ParentCode: 0,
    },
    {
      BankCode: 1150,
      BankName: "Manipur Women's Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1151,
      BankName: "Mizoram Urban Co-Op Development Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1152,
      BankName: "Moirang Primary Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1153,
      BankName: "Nagarik Samabay Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1154,
      BankName: "Nalbari Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1155,
      BankName: "Shillong Co op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1156,
      BankName: "Tura Urban Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1157,
      BankName: "A.P. Mahajans Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1158,
      BankName: "A.P.Janata Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1159,
      BankName: "Adarsh Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1160,
      BankName: "Agroha Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1161,
      BankName: "Bhadradri Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1162,
      BankName: "Bhavana  Rishi Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1163,
      BankName: "Chaitanya Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1164,
      BankName: "Cumbum Co-Operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1165,
      BankName: "Darus Salam Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1166,
      BankName: "Devi Gayatri Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1167,
      BankName: "Eluri Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1168,
      BankName: "Eluru Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1169,
      BankName: "Gayatri Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1170,
      BankName: "Gooty Co-Operative  Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1171,
      BankName: "Guntur Women Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1172,
      BankName: "Jagruti  Co-Operative Urban  Bank  Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1173,
      BankName: "Jampeta Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1174,
      BankName: "Jubilee Hills Mercantile Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1175,
      BankName: "Kakatiya Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1176,
      BankName: "Kanaka Mahalakshmi Co-Operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1177,
      BankName: "Koilkuntla Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1178,
      BankName: "Kranti Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1179,
      BankName: "L.I.C.Employees' Co-Operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1180,
      BankName: "Mahanagar Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1181,
      BankName: "Maharaja Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1182,
      BankName: "Maharana Pratap Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1183,
      BankName: "Mahaveer Co-Operative Urban  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1184,
      BankName: "Model Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1185,
      BankName: "Navabharat Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1186,
      BankName: "Neela Krishna Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1187,
      BankName: "Padmavati Co-Operative Urban  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1188,
      BankName: "Pochampally Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1189,
      BankName: "Priyadarshini Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1190,
      BankName: "Proddatur Co-Operative  Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1191,
      BankName: "Rajadhani Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1192,
      BankName: "Ranga Reddy Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1193,
      BankName: "Salur Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1194,
      BankName: "Seven Hills Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1195,
      BankName: "Shree Mahayogi Lakshmamma Co-Operative",
      ParentCode: 0,
    },
    {
      BankCode: 1196,
      BankName: "Sind Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 325,
      BankName: "Banki District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 326,
      BankName: "Behrampore District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 327,
      BankName: "Bhawanipatna Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 328,
      BankName: "The Boudh Co-operative Central Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 329,
      BankName: "Cuttack Central Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 330,
      BankName: "Keonjhar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 331,
      BankName: "Khurda Central Co-opertive Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 332,
      BankName: "Koraput Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 333,
      BankName: "Mayurbhanj Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 334,
      BankName: "Nayagarh Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 335,
      BankName: "Sambalpur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 336,
      BankName: "Sundargarh District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 337,
      BankName: "United Puri Nimpara Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 338,
      BankName: "Amritsar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 339,
      BankName: "Bhatinda Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 340,
      BankName: "Faridcot Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 341,
      BankName: "Fatehgarh Sahib Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 342,
      BankName: "Fazilka Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 343,
      BankName: "Ferozepur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 344,
      BankName: "Gurdaspur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 345,
      BankName: "Hoshiarpur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 346,
      BankName: "Jalandhar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 347,
      BankName: "Kapurthala Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 348,
      BankName: "Ludhiana Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 349,
      BankName: "Mansa Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 350,
      BankName: "Moga Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 351,
      BankName: "Muktsar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 352,
      BankName: "Nawanshahr Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 353,
      BankName: "Patiala Central Co-operative Bank Ltd. .",
      ParentCode: 0,
    },
    {
      BankCode: 354,
      BankName: "Ropar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 355,
      BankName: "Sangrur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 356,
      BankName: "Tarn Taran Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 357,
      BankName: "S.A.S.Nagar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 358,
      BankName: "Ajmer Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 359,
      BankName: "Alwar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 360,
      BankName: "Banswara Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 361,
      BankName: "Baran Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 362,
      BankName: "Barmer Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 363,
      BankName: "Bharatpur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 364,
      BankName: "Bhilwara Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 365,
      BankName: "Bikaner Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 366,
      BankName: "Bundi Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 367,
      BankName: "Chittorgarh Kendriya Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 368,
      BankName: "Churu Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 369,
      BankName: "Dungarpur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 370,
      BankName: "Dausa Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 371,
      BankName: "Ganganagar Kendriya Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 372,
      BankName: "Hanumangarh Kendriya Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 373,
      BankName: "Jaipur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 374,
      BankName: "Jaisalmer Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 375,
      BankName: "Jalore Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 376,
      BankName: "Jhalawar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 377,
      BankName: "Jhunjhunu Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 378,
      BankName: "Jodhpur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 379,
      BankName: "Kota Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 380,
      BankName: "Nagaur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 381,
      BankName: "Pali Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 382,
      BankName: "Sawai Madhopur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 383,
      BankName: "Sikar Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1197,
      BankName: "Sree Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1198,
      BankName: "Sreenivasa Padmavathi Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1199,
      BankName: "Sri Bharathi Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1200,
      BankName: "Sri Gayatri Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1201,
      BankName: "Sri Kalahasti Co-Operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1202,
      BankName: "Sri Sharadamba Mahila Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1203,
      BankName: "Stambhadri Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1204,
      BankName: "Sudha Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1205,
      BankName: "Tenali Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1206,
      BankName: "The A.P.Raja Rajeswari Mahila Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1207,
      BankName: "The Agrasen Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1208,
      BankName: "The Amalapuram Co-Operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1209,
      BankName: "The Anantapur Co-Operative  Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1210,
      BankName: "The Andhra Bank Employees Co-Operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1211,
      BankName: "The Aryapuram Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1212,
      BankName: "The Bapatla Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1213,
      BankName: "The Catholic Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1214,
      BankName: "The Chittoor Co-Operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1215,
      BankName: "The Deccan Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1216,
      BankName: "The Dharmavaram Co-Operative  Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1217,
      BankName: "The Durga Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1218,
      BankName: "The Eenadu Co-Operative Urban bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1219,
      BankName: "The Gandhi Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1220,
      BankName: "The Gudivada Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1221,
      BankName: "The Guntur Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1222,
      BankName: "The Hindustan Shipyard Staff Co-Operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1223,
      BankName: "The Innespeta Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1224,
      BankName: "The Kakinada Co-Operative  Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1225,
      BankName: "The Karimnagar Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1226,
      BankName: "The Kovvur Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1227,
      BankName: "The Kuppam Co-Operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1228,
      BankName: "The Madanapalle Co-Operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1229,
      BankName: "The Mandapeta Co-Operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1230,
      BankName: "The Masulipatnam Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1231,
      BankName: "The Nellore Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1232,
      BankName: "The Palamoor Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1233,
      BankName: "The Repalle Co-Operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1234,
      BankName: "The Sanghamitra Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1235,
      BankName: "The Secunderabad Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1236,
      BankName: "The Secunderabad Mercantile Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1237,
      BankName: "The Sircilla Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1238,
      BankName: "The Swarna Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1239,
      BankName: "The Swasakthi Mercantile Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1240,
      BankName: "The Tadpatri Co-Operative  Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1241,
      BankName: "The Tirupati Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1242,
      BankName: "The Visakhapatnam Co-Operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1243,
      BankName: "The Yemmiganur Co-Operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1244,
      BankName: "Tirumala Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1245,
      BankName: "Universal Co-Operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1246,
      BankName: "Uravakonda Co-Operative  Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1247,
      BankName: "Vani Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1248,
      BankName: "Vardhaman  (Mahila) Co-Operative  Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1249,
      BankName: "Warangal Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1250,
      BankName: "Adarsh Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1251,
      BankName: "Balotra Urban Coop.Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1252,
      BankName: "Baran Nagarik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 384,
      BankName: "Sirohi Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 385,
      BankName: "Tonk Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 386,
      BankName: "Udaipur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 387,
      BankName: "Chennai Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 388,
      BankName: "Thoothukudi Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 389,
      BankName: "Coimbatore District Central Co -opertive Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 390,
      BankName: "Cuddalore District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 391,
      BankName: "Dharampuri District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 392,
      BankName: "Dindigul Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 393,
      BankName: "The Erode District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 394,
      BankName: "The Kancheepuram Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 395,
      BankName: "Kanyakumari District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 396,
      BankName: "Kumbakonam Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 397,
      BankName: "Madurai District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 398,
      BankName: "Nilgiris Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 399,
      BankName: "Vellore District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 400,
      BankName: "Padukkottai Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 401,
      BankName: "Ramanathapuram District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 402,
      BankName: "Salem District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 403,
      BankName: "Sivagangai District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 404,
      BankName: "Thanjavur Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 405,
      BankName: "Tiruvannamalai District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 406,
      BankName: "Tiruchirapalli District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 407,
      BankName: "Tirunelveli Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 408,
      BankName: "Villupuram District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 409,
      BankName: "Virudhunagar District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 410,
      BankName: "The Tamilnadu Industrial Co-operative Bank (TAICO)",
      ParentCode: 0,
    },
    {
      BankCode: 411,
      BankName: "Agra Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 412,
      BankName: "Aligarh Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 413,
      BankName: "Allahabad District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 414,
      BankName: "Varanasi District Central Co - operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 415,
      BankName: "Azamgarh District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 416,
      BankName: "Bahraich District central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 417,
      BankName: "Ballia District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 418,
      BankName: "Banda District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 419,
      BankName: "Barabanki District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 420,
      BankName: "Bareilly Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 421,
      BankName: "Basti District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 422,
      BankName: "Bijnor Jilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 423,
      BankName: "Budaun Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 424,
      BankName: "Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 425,
      BankName: "Deoria Kasia District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 426,
      BankName: "Etah District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 427,
      BankName: "Etawah Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 428,
      BankName: "Faizabad Co-operative District Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 429,
      BankName: "Farrukhabad District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 430,
      BankName: "Fathehpur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 431,
      BankName: "Firozabad District Central Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 432,
      BankName: "Ghaziabad District Central Co-operative Bank Ltd. .",
      ParentCode: 0,
    },
    {
      BankCode: 433,
      BankName: "Ghazipur District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 434,
      BankName: "Gorakhpur Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 435,
      BankName: "Hamirpur District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 436,
      BankName: "Hardoi District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 437,
      BankName: "Jalaun District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 438,
      BankName: "Jaunpur Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 439,
      BankName: "Kanpur Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 440,
      BankName: "Lakhimpur -Kheri District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1253,
      BankName: "Beawar Urban Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1254,
      BankName: "Bharatpur Urban Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1255,
      BankName: "Bhilwara Urban Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1256,
      BankName: "Bundi Urban Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1257,
      BankName: "Chittorgarh Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1258,
      BankName: "Churu Zilla Urban Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1259,
      BankName: "Dausa Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1260,
      BankName: "Dholpur Urban Co-op.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1261,
      BankName: "Fingrowth Co-operative Bank Ltd. Jaipur",
      ParentCode: 0,
    },
    {
      BankCode: 1262,
      BankName: "Integral Urban co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1263,
      BankName: "Jalore Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1264,
      BankName: "Jhalawar Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1265,
      BankName: "Jodhpur Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1266,
      BankName: "Kota Mahila Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1267,
      BankName: "Kota Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1268,
      BankName: "Malviya Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1269,
      BankName: "Nagaur Urban Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1270,
      BankName: "Pali Urban Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1271,
      BankName: "Railway Employees' Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1272,
      BankName: "Railway Employees'Coop Banking Soc Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1273,
      BankName: "Railway Shramik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1274,
      BankName: "Raj Laxmi Mahila Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1275,
      BankName: "Rajasthan  Urban  Co-Operative  Bank  Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1276,
      BankName: "Rajputana Mahila Urban Co-op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1277,
      BankName: "Rajsamand Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1278,
      BankName: "Sawai Madhopur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1279,
      BankName: "Sikar Urban Co Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1280,
      BankName: "Sterling Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1281,
      BankName: "Udaipur Mahila Samridhhi Urban Coop Bk Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1282,
      BankName: "Udaipur Mahila Urban Co-op.Bk.Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1283,
      BankName: "Udaipur Urban Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1284,
      BankName: "The Citizens' Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1285,
      BankName: "The Devika Urban Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1286,
      BankName: "The Kashmir Mercantile Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1287,
      BankName: "The Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1288,
      BankName: "Balitikuri Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1289,
      BankName: "Bally Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1290,
      BankName: "Bhatpara Naihati Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1291,
      BankName: "Budge-Budge Nangi Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1292,
      BankName: "Contai Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1293,
      BankName: "Dhakuria Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1294,
      BankName: "Durgapur Mahila Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1295,
      BankName: "Durgapur Steel Peoples' Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1296,
      BankName: "Eastern & North East Frontier Railway Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1297,
      BankName: "Khardah Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1298,
      BankName: "Kolikata Mahila Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1299,
      BankName: "Kolkata Police Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1300,
      BankName: "Konnagar Samabaya Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1301,
      BankName: "Liluah Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1302,
      BankName: "Nabagram People's Co-operative Credit Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1303,
      BankName: "National Insurance Emp. Co-operative Cr.&",
      ParentCode: 0,
    },
    {
      BankCode: 1304,
      BankName: "Ranaghat People's Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1305,
      BankName: "Raniganj Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1306,
      BankName: "Samata Co-operative Development Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1307,
      BankName: "Sree Chaitanya Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1308,
      BankName: "The Baidyabati Sheoraphuli Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1309,
      BankName: "The Bank Employees' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1310,
      BankName: "The Bankura Town Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 441,
      BankName: "Lalitpur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 442,
      BankName: "Mainpuri District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 443,
      BankName: "Mathura Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 444,
      BankName: "Meerut District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 445,
      BankName: "Mirzapur Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 446,
      BankName: "Moradabad Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 447,
      BankName: "Pilibhit District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 448,
      BankName: "Pratapgarh Jilla Sahakari Bank Ltd. .",
      ParentCode: 0,
    },
    {
      BankCode: 449,
      BankName: "Rae Bareli District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 450,
      BankName: "Rampur District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 451,
      BankName: "Saharanpur District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 452,
      BankName: "Shahjahanpur District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 453,
      BankName: "Siddarthnagar District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 454,
      BankName: "Sultanpur District Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 455,
      BankName: "Almora Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 456,
      BankName: "Chamoli Zilla Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 457,
      BankName: "Dehradun District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 458,
      BankName: "Tehri Zila Shakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 459,
      BankName: "Uttarkashi Zila Sahkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 460,
      BankName: "Zila Sahkari Bank Ltd. Garhwal (Kotdwar)",
      ParentCode: 0,
    },
    {
      BankCode: 461,
      BankName: "Nainital District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 462,
      BankName: "Pithoragarh Zila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 463,
      BankName: "Udham Singh Nagar District Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 464,
      BankName: "Balageria Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 465,
      BankName: "Dakshin Dinajpur District Central Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 466,
      BankName: "Bankura District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 467,
      BankName: "Birbhum District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 468,
      BankName: "Burdwan Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 469,
      BankName: "Darjeeling District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 470,
      BankName: "Hoogly District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 471,
      BankName: "Howrah District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 472,
      BankName: "The Jalpaiguri Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 473,
      BankName: "Malda District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 474,
      BankName: "Mugberia Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 475,
      BankName: "Murshidabad District Central Co -oper ative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 476,
      BankName: "Nadia District Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 477,
      BankName: "Purulia Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 478,
      BankName: "Raiganj Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 479,
      BankName: "Tamluk-Ghatal Central Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 480,
      BankName: "Vidyasagar Central Co operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 481,
      BankName: "Akhand Anand Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 482,
      BankName: "Alavi Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 483,
      BankName: "Amarnath Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 484,
      BankName: "Amod Nagric Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 485,
      BankName: "Amreli Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 486,
      BankName: "Anand Mercantile Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 487,
      BankName: "Apani Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 488,
      BankName: "Balsinor Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 489,
      BankName: "Banaskantha Mercantile co-op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 490,
      BankName: "Bapunagar Mahila Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 491,
      BankName: "Bardoli Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 492,
      BankName: "Baroda City Co-op. Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 493,
      BankName: "Bavla Nagarik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 494,
      BankName: "Bechraji Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 495,
      BankName: "Bhabhar Vibhag Nagarik sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 496,
      BankName: "Bhadran People's Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 497,
      BankName: "Bhagyodaya Co-op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 498,
      BankName: "Bhuj Commercial Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1311,
      BankName: "The Bantra Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1312,
      BankName: "The Bishnupur Town Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1313,
      BankName: "The Boral Union Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1314,
      BankName: "The Eastern Railway Employees'Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 1315,
      BankName: "The Ghatal Peoples' Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1316,
      BankName: "The Hooghly Co-operative Credit Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1317,
      BankName: "The Jaynagar Mozilpur Peoples' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1318,
      BankName: "The Kalna Town Credit Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1319,
      BankName: "The Khatra Peoples' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1320,
      BankName: "The Krishnagar City Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1321,
      BankName: "The Midnapore People's Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1322,
      BankName: "The Nabadwip Co-operative Credit Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1323,
      BankName: "The Nabapalli Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1324,
      BankName: "The Panihati Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1325,
      BankName: "The Santragachi Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1326,
      BankName: "The Shibpur Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1327,
      BankName: "The Suri Friends' Union Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1328,
      BankName: "The Union Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1329,
      BankName: "The Uttarpara Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1330,
      BankName: "(510)Army Base Work-Shop Credit Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 1331,
      BankName: "Adarsh Mahila Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1332,
      BankName: "Banaras Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1333,
      BankName: "Banda Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1334,
      BankName: "Bhadohi Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1335,
      BankName: "Bijnor Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1336,
      BankName: "Citizen Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1337,
      BankName: "Dayalbagh Mahila Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1338,
      BankName: "Development Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1339,
      BankName: "Etah Urban Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1340,
      BankName: "Etawah Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1341,
      BankName: "Ganga Mercantile Urban  Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1342,
      BankName: "Gomthi Nagariya Sahkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1343,
      BankName: "Gulshan Mercantile Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1344,
      BankName: "HCBL Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1345,
      BankName: "Imperial Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1346,
      BankName: "Khalilabad Nagar Sahkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1347,
      BankName: "Lucknow University Adm.Staff Primary Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1348,
      BankName: "Mahoba Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1349,
      BankName: "Mansarovar Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1350,
      BankName: "Mechanical Department Primary Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1351,
      BankName: "N.E. Rly.Emp.Multi State Primary Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 1352,
      BankName: "Nagar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1353,
      BankName: "Nagar Sahkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1354,
      BankName: "Nagar Vikas Sahkari Bank ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1355,
      BankName: "Nagina Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1356,
      BankName: "Nagrik Sahkari Bank Ltd.. Rajajipuram",
      ParentCode: 0,
    },
    {
      BankCode: 1357,
      BankName: "National Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1358,
      BankName: "New Agra Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1359,
      BankName: "New Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1360,
      BankName: "Noble Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1361,
      BankName: "Noida Commercial Co-operative Bank  Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1362,
      BankName: "Northern Railway Primary Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1363,
      BankName: "Omkar Nagriya Sahkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1364,
      BankName: "Ordinance Equip. Factory Prarambhik Sahkari",
      ParentCode: 0,
    },
    {
      BankCode: 1365,
      BankName: "Progressive Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1366,
      BankName: "R.B.I.Employees' Co-op Credit Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1367,
      BankName: "Radhasoami Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 499,
      BankName: "Bhuj Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 500,
      BankName: "Bodeli Urban Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 501,
      BankName: "Chanasma Commercial Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 502,
      BankName: "Chanasma Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 503,
      BankName: "Charda Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 504,
      BankName: "Chhapi Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 505,
      BankName: "Citizen Coop Bank Limited Rajkot.",
      ParentCode: 0,
    },
    {
      BankCode: 506,
      BankName: "Colour Merchants'Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 507,
      BankName: "Commercial Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 508,
      BankName: "Commercial Cooperative Bank Ltd.Jamnagar",
      ParentCode: 0,
    },
    {
      BankCode: 509,
      BankName: "Coop.Bank Of Rajkot Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 510,
      BankName: "Co-operative bank of Mehsana Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 511,
      BankName: "Dahod Mercantile Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 512,
      BankName: "Dahod Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 513,
      BankName: "Dhanera Mercantile Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 514,
      BankName: "Dharmaj Peoples' Co-op.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 515,
      BankName: "Dhinoj Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 516,
      BankName: "Dhrangadhra Peoples'Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 517,
      BankName: "Financial Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 518,
      BankName: "Gandevi People's Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 519,
      BankName: "Gandhidham Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 520,
      BankName: "Gandhidham Mercantile Co-",
      ParentCode: 0,
    },
    {
      BankCode: 521,
      BankName: "Gandhinagar Nagarik Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 522,
      BankName: "Gandhinagar Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 523,
      BankName: "Ghoghamba Vibhag Nagarik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 524,
      BankName: "Godhra City Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 525,
      BankName: "Godhra Urban Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 526,
      BankName: "Gondal Nagarik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 527,
      BankName: "Gozaria Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 528,
      BankName: "Gujarat Ambuja Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 529,
      BankName: "Gujarat Mercantile Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 530,
      BankName: "Halol Mercantile Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 531,
      BankName: "Halol Urban Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 532,
      BankName: "Hansot Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 533,
      BankName: "Harij Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 534,
      BankName: "Himatnagar Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 535,
      BankName: "Idar Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 536,
      BankName: "Jamnagar mahila Sahakari Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 537,
      BankName: "Jamnagar People's Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 538,
      BankName: "Janata Coop. Bank Ltd Godhra.",
      ParentCode: 0,
    },
    {
      BankCode: 539,
      BankName: "Jhalod Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 540,
      BankName: "Jivan Commercial Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 541,
      BankName: "Junagadh Commercial Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 542,
      BankName: "Kalol Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 543,
      BankName: "Kalol Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 544,
      BankName: "Kalyan Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 545,
      BankName: "Kankaria Maninagar Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 546,
      BankName: "Kapadwanj Peoples' Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 547,
      BankName: "Karjan Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 548,
      BankName: "Karnavathi Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 549,
      BankName: "Khambhat Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 550,
      BankName: "Kheda Peoples' Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 551,
      BankName: "Kheralu Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 552,
      BankName: "Kodinar Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 553,
      BankName: "Kosamba Mercantile Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 554,
      BankName: "Kukarwada Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 555,
      BankName: "Lakhvad Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 556,
      BankName: "Lalbaug Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 557,
      BankName: "Limbasi Urban Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1368,
      BankName: "Rajdhani Nagar Sahkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1369,
      BankName: "Ranilaxmibai Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1370,
      BankName: "Sonbhadra Nagar Sahkari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1371,
      BankName: "U. P. Postal Primary Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1372,
      BankName: "U.P.Civil Secretriat Primary Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1373,
      BankName: "United Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1374,
      BankName: "Abhinav Sahakari Bank Ltd.(Dombivali)",
      ParentCode: 0,
    },
    {
      BankCode: 1375,
      BankName: "ACE Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1376,
      BankName: "Adarniya P.D. Patil Saheb Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1377,
      BankName: "Ajara Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1378,
      BankName: "Alibag Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1379,
      BankName: "Aman Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1380,
      BankName: "Ambarnath Jai-Hind Cooperative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1381,
      BankName: "Anna Sahaeb Magar Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1382,
      BankName: "Appasaheb Birnale Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1383,
      BankName: "Ashok Nagri Sahakari Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1384,
      BankName: "Ashta People's Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1385,
      BankName: "Babasaheb Deshmukh Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1386,
      BankName: "Bajirao Appa Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1387,
      BankName: "Baramati Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1388,
      BankName: "Bhagini Nivedita Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1389,
      BankName: "Bhavani Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1390,
      BankName: "Birdeo Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1391,
      BankName: "Chandgad Urban Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1392,
      BankName: "Chembur Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1393,
      BankName: "Chiplun Urban Coop.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1394,
      BankName: "Col R D Nikam  Sainik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1395,
      BankName: "D Y Patil Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1396,
      BankName: "Dahanu Road Janata Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1397,
      BankName: "Dapoli Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1398,
      BankName: "Dattatraya Maharaj Kalambi Jaoli Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1399,
      BankName: "Daund Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1400,
      BankName: "Deccan Merchants Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1401,
      BankName: "Defence Accounts Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1402,
      BankName: "Devgad Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1403,
      BankName: "Dharamvir Sambhaji Urban Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1404,
      BankName: "Dr. Annasahab Chaugule Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1405,
      BankName: "Excellent Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1406,
      BankName: "Gadhinglaj Urban Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1407,
      BankName: "Ganesh Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1408,
      BankName: "Haveli Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1409,
      BankName: "Hindustan Coop Bank LImited.",
      ParentCode: 0,
    },
    {
      BankCode: 1410,
      BankName: "Hutatma Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1411,
      BankName: "Ichalkaranji Merchants Co-op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1412,
      BankName: "Income Tax Department Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1413,
      BankName: "Indapur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1414,
      BankName: "Indrayani Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1415,
      BankName: "Islampur Urban Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1416,
      BankName: "Jai Bhawani Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1417,
      BankName: "Jai Hind Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1418,
      BankName: "Jain Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1419,
      BankName: "Janaseva Sahakari (Borivli) Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1420,
      BankName: "Janaseva Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1421,
      BankName: "Janata Sahakari Bank Ltd. Ajra.",
      ParentCode: 0,
    },
    {
      BankCode: 1422,
      BankName: "Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1423,
      BankName: "Janata Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1424,
      BankName: "Jath Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1425,
      BankName: "Jawahar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1426,
      BankName: "Jawahar Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 558,
      BankName: "Limdi Urban Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 559,
      BankName: "Lunawada Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 560,
      BankName: "Lunawada People's Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 561,
      BankName: "M.S.  Co-Operative  Bank  Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 562,
      BankName: "Mahila Co-Operative Nagrik Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 563,
      BankName: "Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 564,
      BankName: "Mahudha Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 565,
      BankName: "Makarpura Industrial Estate Co-op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 566,
      BankName: "Malpur Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 567,
      BankName: "Mandal Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 568,
      BankName: "Mandvi Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 569,
      BankName: "Mandvi Nagrik Sahakari Bank LTd.",
      ParentCode: 0,
    },
    {
      BankCode: 570,
      BankName: "Maninagar Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 571,
      BankName: "Mansa Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 572,
      BankName: "Marketyard Commercial Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 573,
      BankName: "Megharaj Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 574,
      BankName: "Mehmadabad Urban People's Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 575,
      BankName: "Mehsana Jilla Panchayat Karmachari Co op Bank",
      ParentCode: 0,
    },
    {
      BankCode: 576,
      BankName: "Mehsana Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 577,
      BankName: "Modasa Nagrik Sahakari  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 578,
      BankName: "Nadiad People's Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 579,
      BankName: "Nagarik Sahakari Bank Ltd. Babra",
      ParentCode: 0,
    },
    {
      BankCode: 580,
      BankName: "Naroda Nagrik Coop.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 581,
      BankName: "Navnirman Coop.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 582,
      BankName: "Navsarjan Industrial Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 583,
      BankName: "Nawanagar Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 584,
      BankName: "Nidhi Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 585,
      BankName: "Ode Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 586,
      BankName: "Padra Nagar Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 587,
      BankName: "Panchsheel Mercantile Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 588,
      BankName: "Patan Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 589,
      BankName: "Patdi Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 590,
      BankName: "People's Coop. Bank Ltd.Mochiol Dholka.",
      ParentCode: 0,
    },
    {
      BankCode: 591,
      BankName: "Pij People's Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 592,
      BankName: "Porbandar Commercial Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 593,
      BankName: "Porbandar Vibhagiya Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 594,
      BankName: "Pragati Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 595,
      BankName: "Pragati Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 596,
      BankName: "Prime Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 597,
      BankName: "Progressive Mercantile Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 598,
      BankName: "Rajkot Commercial Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 599,
      BankName: "Rajkot Peoples Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 600,
      BankName: "Rajpipla Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 601,
      BankName: "Rajula Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 602,
      BankName: "Rander People's Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 603,
      BankName: "Randheja Commercial Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 604,
      BankName: "Ranuj Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 605,
      BankName: "Sankheda Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 606,
      BankName: "Santrampur Urban Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 607,
      BankName: "Sarangpur Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 608,
      BankName: "Saraspur Nagarik Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 609,
      BankName: "Sardar Vallabhbhai Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 610,
      BankName: "Sardarganj Mercantile Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 611,
      BankName: "Sardargunj Mercantile Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 612,
      BankName: "Sarsa People's Co op Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 613,
      BankName: "Sarvodaya Commercial Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 614,
      BankName: "Sarvodaya Nagrik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 615,
      BankName: "Sarvodaya Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 616,
      BankName: "Sathamba People's Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1427,
      BankName: "Jaysingpur Udgaon Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1428,
      BankName: "Jivhaji Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1429,
      BankName: "Kagal Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1430,
      BankName: "KNS Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1431,
      BankName: "Kodoli Urban Co-op. Bank Ltd. Kodoli.",
      ParentCode: 0,
    },
    {
      BankCode: 1432,
      BankName: "Kohinoor Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1433,
      BankName: "Kokan Mercantile Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1434,
      BankName: "Kolhapur Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1435,
      BankName: "Kolhapur Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1436,
      BankName: "Konark Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1437,
      BankName: "Koyana  Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1438,
      BankName: "Krishna Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1439,
      BankName: "Kumbhi Kasari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1440,
      BankName: "Kunbi Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1441,
      BankName: "Lala Urban Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1442,
      BankName: "Laxmi Vishnu Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1443,
      BankName: "Lonavla Sahakari Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1444,
      BankName: "M.D.Pawar Peoples Co-operative Bank Ltd.Urun- Islampur",
      ParentCode: 0,
    },
    {
      BankCode: 1445,
      BankName: "Maha.Mantralaya & Allied Offices Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1446,
      BankName: "Mahabaleshwar Urban Co-op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1447,
      BankName: "Mahesh Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1448,
      BankName: "Malad Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1449,
      BankName: "Mangal Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1450,
      BankName: "Manmandhir Co-Op. Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1451,
      BankName: "Manndeshi Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1452,
      BankName: "Mansingh Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1453,
      BankName: "Mayani Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1454,
      BankName: "Model Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1455,
      BankName: "Mogaveera Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1456,
      BankName: "Mumbai Mahanager Palika Shikshan Vibhag",
      ParentCode: 0,
    },
    {
      BankCode: 1457,
      BankName: "Municipal Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1458,
      BankName: "Muslim Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1459,
      BankName: "Nagarik Sahakari Bank Ltd. Bhiwandi.",
      ParentCode: 0,
    },
    {
      BankCode: 1460,
      BankName: "Nandani Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1461,
      BankName: "Nav Jeevan Coop Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1462,
      BankName: "Naval Dockyard Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1463,
      BankName: "Navi Mumbai Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1464,
      BankName: "Om-Datta Chaitanya Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1465,
      BankName: "Palus Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1466,
      BankName: "Panvel Co-op. Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1467,
      BankName: "Parshwanath Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1468,
      BankName: "Patan Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1469,
      BankName: "Patan Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1470,
      BankName: "Pavana Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1471,
      BankName: "Pimpri Chinchwad Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1472,
      BankName: "Poona Marchant's Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1473,
      BankName: "Pratap Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1474,
      BankName: "Prathamik Shikshak Sahakari bank ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1475,
      BankName: "Prerna Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1476,
      BankName: "Pritisangam Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1477,
      BankName: "Priyadarshini Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1478,
      BankName: "Progressive Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1479,
      BankName: "Pune Cantonment Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1480,
      BankName: "Pune Municipal Corporation Servants Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1481,
      BankName: "Pune People's Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1482,
      BankName: "Pune Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1483,
      BankName: "Pune Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1484,
      BankName: "Rahimatpur Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1485,
      BankName: "Raigad Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1486,
      BankName: "Rajapur Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1487,
      BankName: "Rajapur Urban Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 617,
      BankName: "Saurashtra Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 618,
      BankName: "Sevalia Urban Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 619,
      BankName: "Shihori Nagarik Sahakari Bank ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 620,
      BankName: "Shree  Virpur Urban Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 621,
      BankName: "Shree Baria Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 622,
      BankName: "Shree Bharat Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 623,
      BankName: "Shree Bhavnagar Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 624,
      BankName: "Shree Botad Mercantile Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 625,
      BankName: "Shree Coop. Bank Ltd.Baroda.",
      ParentCode: 0,
    },
    {
      BankCode: 626,
      BankName: "Shree Dhandhuka Janta Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 627,
      BankName: "Shree Dharati Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 628,
      BankName: "Shree Kadi Nagrik Sahakari Bank  Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 629,
      BankName: "Shree Laxmi Mahila Sahakari Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 630,
      BankName: "Shree Lodhra Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 631,
      BankName: "Shree Mahuva Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 632,
      BankName: "Shree Parswanth Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 633,
      BankName: "Shree Savarkundla Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 634,
      BankName: "Shree Savli Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 635,
      BankName: "Shree Talaja Nagarik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 636,
      BankName: "Shree Vardhaman Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 637,
      BankName: "Shri Bagasara Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 638,
      BankName: "Shri Bhailalbhai Contractor Smarak Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 639,
      BankName: "Shri Bhavnagar Mahila Nagarik Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 640,
      BankName: "Shri Chhani Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 641,
      BankName: "Shri Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 642,
      BankName: "Shri Mahila Sewa Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 643,
      BankName: "Shri Morbi Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 644,
      BankName: "Shri Vinayak Sahakari Bank Limited. Ahmedabad",
      ParentCode: 0,
    },
    {
      BankCode: 645,
      BankName: "Sihor Mercantile Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 646,
      BankName: "Sihor Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 647,
      BankName: "Sinor Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 648,
      BankName: "Suleimani Coop.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 649,
      BankName: "Surat Mercantile Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 650,
      BankName: "Surat National Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 651,
      BankName: "Talod Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 652,
      BankName: "Tarapur Co-Op Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 653,
      BankName: "Textile Traders'Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 654,
      BankName: "Thasra Peoples'Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 655,
      BankName: "The Adinath Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 656,
      BankName: "The Associate Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 657,
      BankName: "The Gujarat Rajya Karmachari Cooperative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 658,
      BankName: "The Jambusar People's Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 659,
      BankName: "The Kutch Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 660,
      BankName: "The Social Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 661,
      BankName: "The Sutex Co operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 662,
      BankName: "Uma Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 663,
      BankName: "Umreth Urban Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 664,
      BankName: "Una Peoples' Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 665,
      BankName: "Unava Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 666,
      BankName: "Union Co op Bank Limited Naroda.",
      ParentCode: 0,
    },
    {
      BankCode: 667,
      BankName: "United Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 668,
      BankName: "Unjha Nagarik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 669,
      BankName: "Uttarsanda Peoples Coop Bank Ltd;",
      ParentCode: 0,
    },
    {
      BankCode: 670,
      BankName: "Vadali Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 671,
      BankName: "Vadnagar Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 672,
      BankName: "Vallabh Vidhyanagar Commercial Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 673,
      BankName: "Valsad Mahila Nagrik Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 674,
      BankName: "Varachha Co-operative bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 675,
      BankName: "Vejalpur Nagarik Sahakari bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1488,
      BankName: "Rajarshi Shahu Government Servants' Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1489,
      BankName: "Rajarshi Shahu Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1490,
      BankName: "Rajgurunagar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1491,
      BankName: "Rameshwar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1492,
      BankName: "Ramrajya Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1493,
      BankName: "Ratnagiri Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1494,
      BankName: "Rayat Sevak Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1495,
      BankName: "Rendal Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1496,
      BankName: "Revdanda Co-op. Urban bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1497,
      BankName: "Sadhana Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1498,
      BankName: "Sahyadri Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1499,
      BankName: "Samata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1500,
      BankName: "Sampada Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1501,
      BankName: "Sangli District Primary Teacher's Co op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1502,
      BankName: "Sangli Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1503,
      BankName: "Sanmathi Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1504,
      BankName: "Sanmitra Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1505,
      BankName: "Sant Sopankaka Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1506,
      BankName: "Sarvodaya Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1507,
      BankName: "Satara Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1508,
      BankName: "Satyashodhak Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1509,
      BankName: "Sawantwadi Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1510,
      BankName: "Shalini Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1511,
      BankName: "Sharad Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1512,
      BankName: "Shivdaulat Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1513,
      BankName: "Shivneri Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1514,
      BankName: "Shree Gajanan Lokseva Sahakari Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 1515,
      BankName: "Shree Laxmi Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1516,
      BankName: "Shree Laxmi Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1517,
      BankName: "Shree Panchaganga Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1518,
      BankName: "Shree Warana Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1519,
      BankName: "Shreeji Bhatia Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1520,
      BankName: "Shreeram Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1521,
      BankName: "Shri Adinath Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1522,
      BankName: "Shri Arihant Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1523,
      BankName: "Shri Bharat Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1524,
      BankName: "Shri Ganesh Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1525,
      BankName: "Shri Lakshmi Krupa Urban Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1526,
      BankName: "Shri Laxmi Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1527,
      BankName: "Shri Mahalaxmi Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1528,
      BankName: "Shri Nrusingh Saraswati sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1529,
      BankName: "Shri Patneshwar Urban cooprative Bank Ltd. Pen",
      ParentCode: 0,
    },
    {
      BankCode: 1530,
      BankName: "Mamasaheb Pawar Satyavijay Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1531,
      BankName: "Shri Veershaiv Co op Bank Ltd. Kolhapur",
      ParentCode: 0,
    },
    {
      BankCode: 1532,
      BankName: "Shri Yashwant Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1533,
      BankName: "Shrimant Malojiraje Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1534,
      BankName: "Shripatrao Dada Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1535,
      BankName: "Sindhudurg Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1536,
      BankName: "Sree Narayana Guru Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1537,
      BankName: "State Transport Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1538,
      BankName: "Suvarnayug Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1539,
      BankName: "Swatantrya Senani Late Shripal Alase (Kaka)",
      ParentCode: 0,
    },
    {
      BankCode: 1540,
      BankName: "Tasgaon Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1541,
      BankName: "The Annasaheb Savant Co-Op. Urban Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1542,
      BankName: "Udyam Vikas Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1543,
      BankName: "Vaishya Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1544,
      BankName: "Vasai Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1545,
      BankName: "Vidya Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1546,
      BankName: "Vima Kamgar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1547,
      BankName: "Vishwanathrao Patil Murgud Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 676,
      BankName: "Vepar Udhyog Vikas Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 677,
      BankName: "Veraval Mercantile Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 678,
      BankName: "Veraval Peoples Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 679,
      BankName: "Vijapur Nagrik Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 680,
      BankName: "Vijay Commercial Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 681,
      BankName: "Vijay Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 682,
      BankName: "Viramgam Mercantile Coop Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 683,
      BankName: "Waghodia Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 684,
      BankName: "Abhivriddhi Mahila Sahakara Bank Niyamita",
      ParentCode: 0,
    },
    {
      BankCode: 685,
      BankName: "Abhyudaya Mahila Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 686,
      BankName: "Accountant General's Office Employees Co-",
      ParentCode: 0,
    },
    {
      BankCode: 687,
      BankName: "Adarsha Pattana Souharda Sahakara Bank",
      ParentCode: 0,
    },
    {
      BankCode: 688,
      BankName: "Akki-Alur Urban Co-operative Bank Ltd. Akki-Alur",
      ParentCode: 0,
    },
    {
      BankCode: 689,
      BankName: "Almel Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 690,
      BankName: "Alnavar Urban Co-operative Bank Ltd. Alnavar",
      ParentCode: 0,
    },
    {
      BankCode: 691,
      BankName: "Ananda Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 692,
      BankName: "Ankola Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 693,
      BankName: "Aroodhjyoti Pattan Sahakara Bank Niyamith",
      ParentCode: 0,
    },
    {
      BankCode: 694,
      BankName: "Arsikere Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 695,
      BankName: "Aruna Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 696,
      BankName: "Arya Vaishya Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 697,
      BankName: "Ashoknagar Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 698,
      BankName: "Azad Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 699,
      BankName: "Bagalkot Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 700,
      BankName: "Bailhongal Merchants' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 701,
      BankName: "Bailhongal Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 702,
      BankName: "Bangalore City Co-operative Bank Ltd. Bangalore",
      ParentCode: 0,
    },
    {
      BankCode: 703,
      BankName: "Bapuji Co-operative Bank Ltd. Davangere",
      ParentCode: 0,
    },
    {
      BankCode: 704,
      BankName: "Basaveshwar Sahakar Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 705,
      BankName: "Bedkihal Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 706,
      BankName: "Belgaum District Revenue Employees' Co-",
      ParentCode: 0,
    },
    {
      BankCode: 707,
      BankName: "Belgaum Industrial Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 708,
      BankName: "Belgaum Zilla Rani Channamma Mahila Sahakari",
      ParentCode: 0,
    },
    {
      BankCode: 709,
      BankName: "Bellad Bagewadi Urban Souharda Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 710,
      BankName: "Bellary Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 711,
      BankName: "Belur Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 712,
      BankName: "Bharath Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 713,
      BankName: "Bharathiya Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 714,
      BankName: "Bhatkal Urban Co-operative Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 715,
      BankName: "Bhavasar Kshatriya Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 716,
      BankName: "Bhavsara Kshatriya Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 717,
      BankName: "Bijapur District Mahila Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 718,
      BankName: "Shri Mahalaxmi Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 719,
      BankName: "Bilagi Pattan Sahakari Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 720,
      BankName: "Cardamom Merchants Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 721,
      BankName: "Chaitanya Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 722,
      BankName: "Chartered Sahakari Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 723,
      BankName: "Chikmagalur Jilla Mahila Sahakara Bank",
      ParentCode: 0,
    },
    {
      BankCode: 724,
      BankName: "Chikmagalur Pattana Sahakara Bank Nayamitha",
      ParentCode: 0,
    },
    {
      BankCode: 725,
      BankName: "City Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 726,
      BankName: "City Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 727,
      BankName: "Comptroller's Office Co-operative Bank Ltd..",
      ParentCode: 0,
    },
    {
      BankCode: 728,
      BankName: "Daivadnya Sahakara Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 729,
      BankName: "Dakshina Kannada Jilla Mahila Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 730,
      BankName: "Davangere Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 731,
      BankName: "Davangere-Harihar Urban Sahakara Bank Niyamitha.",
      ParentCode: 0,
    },
    {
      BankCode: 732,
      BankName: "Deepak Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1548,
      BankName: "Vishweshwar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1549,
      BankName: "Vita Merchants Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1550,
      BankName: "Vita Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1551,
      BankName: "Vyankateshwara Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1552,
      BankName: "Wai Urban Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1553,
      BankName: "Walchandnagar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1554,
      BankName: "Yadrav Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1555,
      BankName: "Yashwant Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1556,
      BankName: "Youth Development Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1557,
      BankName: "Abhinandan Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1558,
      BankName: "Ahilyadevi Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1559,
      BankName: "Ahmednagar Merchants' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1560,
      BankName: "Ahmednagar Shahar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1561,
      BankName: "Ahmednagar Zilla Prathamik Shikshak Saha.Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1562,
      BankName: "Ajantha Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1563,
      BankName: "Amalner Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1564,
      BankName: "Ambajogai Peoples Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1565,
      BankName: "Ambica Mahila Sahakari Bank Ltd.Ahmednagar.",
      ParentCode: 0,
    },
    {
      BankCode: 1566,
      BankName: "Andersul Urban Co-operative Bank   Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1567,
      BankName: "Anuradha Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1568,
      BankName: "Arvind Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1569,
      BankName: "Ashok Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1570,
      BankName: "Bhadgaon Peoples Co-operative Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1571,
      BankName: "Bhausaheb Birajdar Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1572,
      BankName: "Bhavani Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1573,
      BankName: "Bhinger Urban Co-operative Bank Limited",
      ParentCode: 0,
    },
    {
      BankCode: 1574,
      BankName: "Brahmadeodada Mane Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1575,
      BankName: "Bramhapuri Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1576,
      BankName: "Business Co-operative Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1577,
      BankName: "Chandwad Merchant's Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1578,
      BankName: "Chopda People's Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1579,
      BankName: "Dadasaheb Gajmal Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1580,
      BankName: "Dadasaheb Ramrao Patil Co-op.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1581,
      BankName: "Deendayal Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1582,
      BankName: "Deogiri Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1583,
      BankName: "Deola Merchants'Coop Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1584,
      BankName: "Desaiganj Nagari Co-operative Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1585,
      BankName: "Devyani Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1586,
      BankName: "Dhule and Nandurbar Jilha Sarkari Nokaranchi",
      ParentCode: 0,
    },
    {
      BankCode: 1587,
      BankName: "Dhule Vikas Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1588,
      BankName: "Dilip Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1589,
      BankName: "Dr. Babasaheb Ambedkar Nagari Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1590,
      BankName: "Dr. Babasaheb Ambedkar Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1591,
      BankName: "Dr. Babasaheb Ambedkar Urban Co-operative",
      ParentCode: 0,
    },
    {
      BankCode: 1592,
      BankName: "Dr. Jaiprakash Mundada Urban Co-oprative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1593,
      BankName: "Dwarakadas Mantri Nagari Sahakari Bk.",
      ParentCode: 0,
    },
    {
      BankCode: 1594,
      BankName: "Gandhibag Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1595,
      BankName: "Ganraj Nagri Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1596,
      BankName: "Gautam Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1597,
      BankName: "Ghoti Marchants Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1598,
      BankName: "Godavari Laxmi Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1599,
      BankName: "Godavari Urban Co-op.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1600,
      BankName: "Godavari Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1601,
      BankName: "Hasti Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1602,
      BankName: "Indira Mahila Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1603,
      BankName: "Indira Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1604,
      BankName: "Jai Kalimata Mahila Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1605,
      BankName: "Jalna Merchants Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 733,
      BankName: "Dhanashree Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 734,
      BankName: "Gandhi Gunj Co-operative Bank Ltd. Bidar .",
      ParentCode: 0,
    },
    {
      BankCode: 735,
      BankName: "Gokak Urban Co-operative Credit Bank Ltd. Gokak",
      ParentCode: 0,
    },
    {
      BankCode: 736,
      BankName: "Government Employees Co-operative  Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 737,
      BankName: "Graduates' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 738,
      BankName: "Grain Merchants' Co-operative  Bank",
      ParentCode: 0,
    },
    {
      BankCode: 739,
      BankName: "Guardian Souharda Sahakari Bank Niyamita",
      ParentCode: 0,
    },
    {
      BankCode: 740,
      BankName: "Hadgali Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 741,
      BankName: "Hanamasagar Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 742,
      BankName: "Hanumanthanagar Co-operative  Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 743,
      BankName: "Haveri Urban Co-operative Bank Limited. Haveri",
      ParentCode: 0,
    },
    {
      BankCode: 744,
      BankName: "Hira Sugar Employees' Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 745,
      BankName: "Honavar Urban Co-operative  Bank Ltd. Honavar",
      ParentCode: 0,
    },
    {
      BankCode: 746,
      BankName: "Hospet Co-operative City Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 747,
      BankName: "Hotel Industrialists Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 748,
      BankName: "Hubli Urban Co-operative  Bank Limited. Hubli",
      ParentCode: 0,
    },
    {
      BankCode: 749,
      BankName: "Hukeri Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 750,
      BankName: "Ilkal Co-operative Bank Ltd. Ilkal",
      ParentCode: 0,
    },
    {
      BankCode: 751,
      BankName: "Jagruti Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 752,
      BankName: "Jamkhandi Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 753,
      BankName: "Janata Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 754,
      BankName: "Janatha Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 755,
      BankName: "Janatha Seva Co-operative  Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 756,
      BankName: "Jijamata Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 757,
      BankName: "Kalghatgi Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 758,
      BankName: "Kalpavruksha Co-operative  Bank",
      ParentCode: 0,
    },
    {
      BankCode: 759,
      BankName: "Kanaka Pattana Sahakara Bank Niyamita",
      ParentCode: 0,
    },
    {
      BankCode: 760,
      BankName: "Karnataka  Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 761,
      BankName: "Karwar Urban Co-operative  Bank Ltd. Karwar",
      ParentCode: 0,
    },
    {
      BankCode: 762,
      BankName: "Kaujalgi Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 763,
      BankName: "Kempegowda Pattana Souharda Sahakara Bank",
      ParentCode: 0,
    },
    {
      BankCode: 764,
      BankName: "Khanapur Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 765,
      BankName: "Kittur Channamma Mahila Sahakari Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 766,
      BankName: "Kodagu Zilla Mahila Sahakara Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 767,
      BankName: "Koteshwara Sahakari Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 768,
      BankName: "Krishna Pattana Sahakar Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 769,
      BankName: "Kumta Urban Co-operative Bank Ltd",
      ParentCode: 0,
    },
    {
      BankCode: 770,
      BankName: "Kushtagi Pattana Sahakari Bank Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 771,
      BankName: "L.I.C.Employees' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 772,
      BankName: "Laxmi Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 773,
      BankName: "Laxmi Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 774,
      BankName: "Lokapavani Mahila Sahakari Bank Niyamitha",
      ParentCode: 0,
    },
    {
      BankCode: 775,
      BankName: "Madikeri Town Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 776,
      BankName: "Mahalakshmi Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 777,
      BankName: "Mahalingpur Urban Co-Op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 778,
      BankName: "Mahaveer Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 779,
      BankName: "Mahila Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 780,
      BankName: "Mallapur Urban Co-operative Bank Ltd. Ghataprabha",
      ParentCode: 0,
    },
    {
      BankCode: 781,
      BankName: "Malleswaram Co-operative Bank Ltd. Bangalore",
      ParentCode: 0,
    },
    {
      BankCode: 782,
      BankName: "Mandya City Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 783,
      BankName: "Mangalore Catholic Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 784,
      BankName: "Mangalore Co-operative Town Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 785,
      BankName: "Manipal Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 786,
      BankName: "Manjra Mahila Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 787,
      BankName: "Manvi Pattana Souharda Sahakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 788,
      BankName: "Maratha Co-operative Urban Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 789,
      BankName: "Merchants' Co-operative  Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1606,
      BankName: "Janakalyan Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1607,
      BankName: "Janata Coop. Bank Ltd. Malegaon.",
      ParentCode: 0,
    },
    {
      BankCode: 1608,
      BankName: "Janata Sahakari Bank Limited.",
      ParentCode: 0,
    },
    {
      BankCode: 1609,
      BankName: "Janata Sahakari Bank Ltd. Kurduwadi.",
      ParentCode: 0,
    },
    {
      BankCode: 1610,
      BankName: "Jankalyan Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1611,
      BankName: "Janseva Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1612,
      BankName: "Janseva Nagari Sahakari Bank Maryadit",
      ParentCode: 0,
    },
    {
      BankCode: 1613,
      BankName: "Jansewa Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1614,
      BankName: "Jay Tuljabhavani Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1615,
      BankName: "Jijamata Mahila Nagri Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1616,
      BankName: "Jijau Commercial Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1617,
      BankName: "Jugalkishor Tapdia Shri Mahesh Urban Co-",
      ParentCode: 0,
    },
    {
      BankCode: 1618,
      BankName: "Kalwan Marchants' Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1619,
      BankName: "Kalyansagar Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1620,
      BankName: "Kamala Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1621,
      BankName: "Karmala Urban Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1622,
      BankName: "Kavita Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1623,
      BankName: "Kedarnath Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1624,
      BankName: "Kisan Nagri Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1625,
      BankName: "Kopargaon Peoples' Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1626,
      BankName: "Krishna Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1627,
      BankName: "Krushiseva Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1628,
      BankName: "Lasalgaon Merchant's Coop.Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1629,
      BankName: "Lokmangal  Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1630,
      BankName: "Lokneta Dattaji Patil Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1631,
      BankName: "Loknete R. D. (Appa) Kshirsagar Shakari Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1632,
      BankName: "Lokvikas Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1633,
      BankName: "Madheshwari Urban Development Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1634,
      BankName: "Maharashtra Nagari Sahakari Bank Maryadit.",
      ParentCode: 0,
    },
    {
      BankCode: 1635,
      BankName: "Mahatma Fule Urban Co-operative Bank  Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1636,
      BankName: "Mahesh Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1637,
      BankName: "Malegaon Merchants Co-op Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1638,
      BankName: "Manmad Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1639,
      BankName: "Manorama Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1640,
      BankName: "Manwath Urban Co-Operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1641,
      BankName: "Markandey Nagari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1642,
      BankName: "Matoshri Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1643,
      BankName: "Modern Co-op. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1644,
      BankName: "Mohol Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1645,
      BankName: "Moti Urban Cooperative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1646,
      BankName: "Mula Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1647,
      BankName: "Nagnath Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1648,
      BankName: "Nagpur Mahanagarpalika Karmachari Sahahakari",
      ParentCode: 0,
    },
    {
      BankCode: 1649,
      BankName: "Nandurbar Merchants' Co-op Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1650,
      BankName: "Nasik District Industrial & Mercantile Co-op Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1651,
      BankName: "Nasik Jilha Mahila Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1652,
      BankName: "Nasik Road Deolali Vyapari Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1653,
      BankName: "Nasik Zilla Mahila Vikas Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1654,
      BankName: "Nasik Zilla Sar & Par Karmachari Sah Bk Niyamit",
      ParentCode: 0,
    },
    {
      BankCode: 1655,
      BankName: "Navapur Mercantile Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1656,
      BankName: "Neelkanth  Co-op erative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1657,
      BankName: "Niphad Urban Coop. Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1658,
      BankName: "Nirmal Urban Co-operative Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1659,
      BankName: "Nishigandha Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1660,
      BankName: "Ojhar Merchants Coop Bank ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1661,
      BankName: "Omerga Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
    {
      BankCode: 1662,
      BankName: "Omprakash Deora Peoples' Co-operative Bank",
      ParentCode: 0,
    },
    {
      BankCode: 1663,
      BankName: "Osmanabad Janata Sahakari Bank Ltd.",
      ParentCode: 0,
    },
  ];

  let bankNames = bankNamesData.sort((a, b) =>
    a.BankName.localeCompare(b.BankName)
  );

  const OtherNominie = [
    { value: "0", label: "Select" },
    { value: "Mother", label: "Mother" },
    { value: "Father", label: "Father" },
    { value: "Grand Father", label: "Grand Father" },
    { value: "Grand Mother", label: "Grand Mother" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
    { value: "Self", label: "Self" },
    { value: "Husband", label: "Husband" },
    { value: "Wife", label: "Wife" },
    { value: "Father In Law", label: "Father In Law" },
    { value: "Mother In Law", label: "Mother In Law" },
    { value: "Uncle", label: "Uncle" },
    { value: "Aunt", label: "Aunt" },
    { value: "Grand Daughter", label: "Grand Daughter" },
    { value: "Grand Son", label: "Grand Son" },
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
  ];

  // kyc validation functions------------------
  const validateFirstName = (firstName) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!firstName) {
      setFirstNameErr("First name is required.");
      return false;
    } else if (!nameRegex.test(firstName)) {
      setFirstNameErr("First name should only contain alphabets.");

      return false;
    } else {
      setFirstNameErr("");

      return true; // No error
    }
  };
  const validateMiddleName = (middleName) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (middleName && !nameRegex.test(middleName)) {
      setMiddleNameErr("First name should only contain alphabets.");
      return false;
    } else {
      setMiddleNameErr("");
      return true; // No error
    }
  };

  const validateLastName = (firstName) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!firstName) {
      setLastNameErr("Last name is required.");
      return false;
    } else if (!nameRegex.test(firstName)) {
      setLastNameErr("Last name should only contain alphabets.");

      return false;
    } else {
      setLastNameErr("");

      return true; // No error
    }
  };

  const validateDocType = (docType) => {
    if (!docType) {
      setDocTypeErr("Please select a document type.");
      return false;
    } else {
      setDocTypeErr("");

      return true; // No error
    }
  };

  const validateDob = (value) => {
    let ca = calculateAge(value);

    if (!value) {
      setDobErr("Date of birth cannot be blank");
      return false;
    }
    // const dateOfBirth = dayjs(value, "DD-MM-YYYY", true);
    // if (!dateOfBirth.isValid()) {

    //   setDobErrorProposer("Invalid Date of Birth format.");
    //   return false;

    // }

    if (ca > 99) {
      setDobErr("Please select a valid date of birth (age must be at most 99)");
      return false;
    } else {
      setDobErr("");
      return true;
    }
  };

  const validateDobNom = (value) => {
    let ca = calculateAge(value);

    if (!value) {
      setNomDobErr("Date of birth cannot be blank");
      return false;
    }
    // const dateOfBirth = dayjs(value, "DD-MM-YYYY", true);
    // if (!dateOfBirth.isValid()) {

    //   setDobErrorProposer("Invalid Date of Birth format.");
    //   return false;

    // }

    if (ca > 99) {
      setNomDobErr(
        "Please select a valid date of birth (age must be at most 99)"
      );
      return false;
    } else {
      setNomDobErr("");
      return true;
    }
  };

  const validateDocNumber = (docNumber) => {
    const docNumberRegex = /^[A-Za-z0-9]+$/; // Allow only alphanumeric characters

    if (!docNumber) {
      setDocNumErr("Document number is required.");
      return false;
    } else if (!docNumberRegex.test(docNumber)) {
      setDocNumErr("Document number should only contain letters and numbers.");
      return false;
    } else if (docNumber.length < 5) {
      setDocNumErr("Document number must be at least 5 characters long.");
      return false;
    } else {
      setDocNumErr("");
      return true; // No error
    }
  };

  const validateFullName = (fullName) => {
    const nameRegex = /^[A-Za-z\s]+$/; // Allow only alphabets and spaces

    if (!fullName) {
      setNomNameErr("Full name is required.");
      return false;
    } else if (!nameRegex.test(fullName)) {
      setNomNameErr("Full name should only contain alphabets and spaces.");
      return false;
    } else if (fullName.trim().length < 3) {
      setNomNameErr("Full name must be at least 3 characters long.");
      return false;
    } else {
      setNomNameErr("");
      return true; // No error
    }
  };

  const validateRelationship = (relationship) => {
    if (!relationship) {
      setNomRelErr("Relationship with proposer is required.");
      return false;
    } else {
      setNomRelErr("");
      return true; // No error
    }
  };

  const validateNomAddress = (address) => {
    if (!address) {
      setNomAddressError("Address is required.");
      return false;
    } else if (address.length < 5) {
      setNomAddressError("Address must be at least 5 characters long.");
      return false;
    } else {
      setNomAddressError("");
      return true; // No error
    }
  };

  const validateEmail = (email) => {
    let newErrors = "";

    if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors = "Valid email is required";
    }
    setEmailError(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const validateStreet = (street) => {
    let isValid = true;
    if (!street || street.trim() == "") {
      setKycAddressError("Street address is required");
      isValid = false;
      return;
    }
    setKycAddressError("");
    return isValid;
  };

  const validateName = (name) => {
    if (!name?.trim()) {
      setNameError("Name is required");
      return false;
    }
    if (!/^[a-zA-Z\s]+$/.test(name)) {
      setNameError("Name can only contain letters and spaces");
      return false;
    }
    setNameError(""); // Clear error if valid
    return true;
  };
  // const validateBankName = (bankname) => {
  //   let isValid = true;
  //   return isValid;
  // }

  // To store error messages

  // const validateAccountNumber = (accountNumber) => {
  //   if (!accountNumber?.trim()) {
  //     setAccountError("Account Number is required");
  //     return false;
  //   }
  //   if (!/^\d+$/.test(accountNumber)) {
  //     setAccountError("Account Number must be numeric");
  //     return false;
  //   }
  //   if (accountNumber.length < 8 || accountNumber.length > 16) {
  //     setAccountError("Account Number must be between 8 and 16 digits");
  //     return false;
  //   }
  //   setAccountError(""); // Clear error if valid
  //   return true;
  // };

  const validateAccountNumber = (accountNumber) => {
    if (!accountNumber?.trim()) {
      setAccountError("Account Number is required");
      return false;
    }
    // Check if account number is numeric only, no spaces, and length is <= 21
    if (!/^\d{1,21}$/.test(accountNumber)) {
      setAccountError(
        "Account Number must be numeric and up to 21 digits, without spaces or special characters"
      );
      return false;
    }
    setAccountError(""); // Clear error if valid
    return true;
  };

  const validateBankName = (bankName) => {
    if (!bankName) {
      setBankNameError("Bank Name is required");
      return false;
    }
    setBankNameError(""); // Clear error if valid
    return true;
  };

  // const validateIFSCCode = (ifscCode) => {
  //   const ifscPattern = /^[A-Z]{4}[0-9A-Z]{7}$/; // Regex for IFSC code
  //   if (!ifscCode?.trim()) {
  //     setIfscError("IFSC Code is required");
  //     return false;
  //   }
  //   if (ifscCode.length !== 11) {
  //     setIfscError("IFSC Code must be exactly 11 characters long");
  //     return false;
  //   }
  //   if (!ifscPattern.test(ifscCode)) {
  //     setIfscError("Invalid IFSC Code format");
  //     return false;
  //   }
  //   setIfscError(""); // Clear error if valid
  //   return true;
  // };

  const validateIFSCCode = (ifscCode) => {
    // Regex to check alphanumeric only, up to 15 characters
    const ifscPattern = /^[A-Z0-9]{1,15}$/; // Alphanumeric, length up to 15

    if (!ifscCode?.trim()) {
      setIfscError("IFSC Code is required");
      return false;
    }
    if (ifscCode.length > 15) {
      setIfscError("IFSC Code must be a maximum of 15 characters long");
      return false;
    }
    if (!ifscPattern.test(ifscCode)) {
      setIfscError(
        "IFSC Code must be alphanumeric, with no spaces or special characters"
      );
      return false;
    }

    setIfscError(""); // Clear error if valid
    return true;
  };

  const isValidIndianPhoneNumber = (phoneNumber) => {
    const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
    return indianPhoneNumberRegex.test(phoneNumber);
  };
  const validateNomPhoneNumber = (phoneNumber) => {
    let isValid = true;
    if (phoneNumber === "") {
      setNomPhoneError("Phone number is required");
      isValid = false;
    } else if (!isValidIndianPhoneNumber(phoneNumber)) {
      setNomPhoneError("Please enter a valid Indian phone number");
      isValid = false;
    } else if (phoneNumber.length !== 10) {
      setNomPhoneError("Phone number must be 10 digits");
      isValid = false;
    } else {
      setNomPhoneError("");
    }
    return isValid;
  };

  // const validatePinCode = (pincode) => {
  //   let isValid = true;
  //   //check here
  //   return isValid;
  // };

  const validatePinCode = (selectedPinCode, pinCodeList) => {
    let isValid;
    if (!selectedPinCode) {
      isValid = false;
      setPinError("pincode is required");
      return false;
    } else {
      isValid = true;
      setPinError("");
      return true;
    }
  };

  // full kyc form validation
  const validateKycForm = () => {
    let phoneNumberValid;
    let nameValid;
    let bankNameValid;
    let accountNumberValid;
    let ifscCodeValid;

    //nominee
    let nomineerelationshipValid = validateRelationship(
      kycData.nomineerelationship
    );
    let nomineeNameValid = validateFullName(kycData.nomineeName);
    let nomineeDateOfbirthValid = validateDobNom(kycData.nomineeDateOfbirth);
    let nomineeAddressValid = validateNomAddress(kycData.nomineeAddress);

    let firstnameValid = validateFirstName(kycData.firstname);
    // let middlenameValid = validateMiddleName(kycData.middlename);
    let lastnameValid = validateLastName(kycData.lastname);
    let NameValid = validateFullName(kycData.nomineeName);
    let KycDateOfbirthValid = validateDob(kycData.KycDateOfbirth);
    let kycIdValid = validateDocType(kycData.kycId);
    let cardNumValid = validateDocNumber(kycData.cardNum);
    // let cardNumValid = true;

    if (["care", "hdfc"].includes(c_id)) {
      nameValid = validateName(kycData.Name);
      bankNameValid = validateBankName(kycData.bankName);
      accountNumberValid = validateAccountNumber(kycData.accountNumber);
      ifscCodeValid = validateIFSCCode(kycData.ifscCode);
    }

    if (c_id == "reliance") {
      phoneNumberValid = validateNomPhoneNumber(kycData.nomineePhoneNumber);
    }

    let EmailValid = validateEmail(kycData.Email);
    let streetValid = validateStreet(kycData.street);
    let PinCodeValid = validatePinCode(kycData.PinCode);

    let allRight;

    if (c_id == "reliance") {
      allRight =
        nomineerelationshipValid &&
        nomineeNameValid &&
        nomineeDateOfbirthValid &&
        nomineeAddressValid &&
        firstnameValid &&
        lastnameValid &&
        NameValid &&
        KycDateOfbirthValid &&
        kycIdValid &&
        cardNumValid &&
        EmailValid &&
        streetValid &&
        PinCodeValid &&
        phoneNumberValid;
    } else if (["care", "hdfc"].includes(c_id)) {
      allRight =
        nomineerelationshipValid &&
        nomineeNameValid &&
        nomineeDateOfbirthValid &&
        nomineeAddressValid &&
        firstnameValid &&
        lastnameValid &&
        NameValid &&
        KycDateOfbirthValid &&
        kycIdValid &&
        cardNumValid &&
        nameValid &&
        bankNameValid &&
        accountNumberValid &&
        ifscCodeValid &&
        EmailValid &&
        streetValid &&
        PinCodeValid;
    } else {
      allRight =
        nomineerelationshipValid &&
        nomineeNameValid &&
        nomineeDateOfbirthValid &&
        nomineeAddressValid &&
        firstnameValid &&
        lastnameValid &&
        NameValid &&
        KycDateOfbirthValid &&
        kycIdValid &&
        cardNumValid &&
        EmailValid &&
        streetValid &&
        PinCodeValid;
    }

    return allRight;
  };

  // Kyc Doc Array

  const kycOptions = [
    { value: "0", label: "select" },
    { value: "D07", label: "PAN" },
    { value: "D02", label: "CKYC" },
    { value: "D03", label: "AADHAAR" },
    { value: "D04", label: "DL" },
    { value: "D05", label: "VOTERID" },
    { value: "D06", label: "PASSPORT" },
    { value: "D030", label: "CIN" },
  ];

  // HDFC Relationship with nomine

  const HdfcrelationshipOptions = [
    { value: 0, label: "select" },
    { value: "Mother", label: "Mother" },
    { value: "Father", label: "Father" },
    { value: "Grand Father", label: "Grand Father" },
    { value: "Grand Mother", label: "Grand Mother" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
    { value: "Self", label: "Self" },
    { value: "Husband", label: "Husband" },
    { value: "Wife", label: "Wife" },
    { value: "Father In Law", label: "Father In Law" },
    { value: "Mother In Law", label: "Mother In Law" },
    { value: "Uncle", label: "Uncle" },
    { value: "Aunt", label: "Aunt" },
    { value: "Grand Daughter", label: "Grand Daughter" },
    { value: "Grand Son", label: "Grand Son" },
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
  ];

  const getData = async () => {
    try {
      let proposalData;
      let response = await getReduxData(unique_id).then((response) => {
        console.log(
          "the response in get reduxdata",
          response.data.data[0].proposal_data
        );

        if (response?.data?.data[0]?.proposal_data) {
          setProposerData(response.data.data[0].proposal_data);
          proposalData = response?.data?.data[0]?.proposal_data;
        }

        if (response?.data?.data[0]?.kyc_data) {
          setKycData(response.data.data[0].kyc_data);
        }
        // addressDocType: selectedDocumentDrop,
        // identityDocType:selectedIDType,
        if (response?.data?.data[0]?.kyc_data?.addressDocType) {
          setSelectedDocumentDrop(
            response?.data?.data[0]?.kyc_data?.addressDocType
          );
        }
        if (response?.data?.data[0]?.kyc_data?.identityDocType) {
          setSelectedIDType(response?.data?.data[0]?.kyc_data?.identityDocType);
        }

        if (Object.keys(response?.data?.data[0]?.kyc_data).length == 0) {
          // setKycData((prevData)=>(
          //   {
          //     ...prevData,
          //     firstname : response.data.data[0].proposal_data?.ProposalName?.split(" ")[0],
          //     lastname : response.data.data[0].proposal_data?.ProposalName?.split(" ")
          //     .slice(1)
          //     .join(" "),
          //     KycDateOfbirth:(response.data.data[0].proposal_data?.dateOfbirth &&
          //       dayjs(response.data.data[0].proposal_data?.dateOfbirth)) ,
          //       PinCode : response.data.data[0].proposal_data?.PinCode

          //   }
          // )

          // )
          setKycData((prevData) => ({
            ...prevData,
            firstname:
              response.data.data[0].proposal_data?.ProposalName?.split(" ")[0],
            lastname: response.data.data[0].proposal_data?.ProposalName?.split(
              " "
            )
              .slice(-1) // The last part will always be the lastname
              .join(" "),
            middlename:
              response.data.data[0].proposal_data?.ProposalName?.split(" ")
                .slice(1, -1)
                .join(" ") || null, // Middlename is everything between the first and last name
            KycDateOfbirth: response.data.data[0].proposal_data?.dateOfbirth,
            PinCode: response.data.data[0].proposal_data?.PinCode,
          }));
        }

        if (response?.data?.data[0]?.kyc_data?.kyc_photo) {
          let data = response?.data?.data[0]?.kyc_data?.kyc_photo;
          setSelectedFile(data?.docfront ? data.docfront : "");
          setSelectedBackFile(data?.docback ? data?.docback : "");
          setSelectedIdentityFile(data?.idfront ? data?.idfront : "");
          setSelectedIdentityBackFile(data?.idback ? data?.idback : "");
        }

        if (response?.data?.data[0]?.kyc_data?.kyc_photo_path) {
          let data = response?.data?.data[0]?.kyc_data?.kyc_photo_path;
          setKycPath(data);
        }
        if (response?.data?.data[0]?.kyc_data?.kyc_extension) {
          let data = response?.data?.data[0]?.kyc_data?.kyc_extension;
          setKycExtension(data);
        }

        if (proposalData && proposalData.InsuredDetail.length != 0) {
          filterLocation = locations.filter(
            (element) =>
              element.District.toLowerCase() ==
              proposalData.District.toLowerCase()
          );

          for (let i = 0; i < filterLocation.length; i++) {
            pinCodeList.push({
              label: filterLocation[i].Pincode,
              state: filterLocation[i].StateName,
            });
          }
        }
      });
    } catch (error) {
      console.log("the error", error);
    }
  };

  const fileuploadData = async (docdata, ext) => {
    try {
      let payloaddata = {
        doc_data: docdata,
        extension: ext,
      };

      let response = await uploadImageBase64(
        payloaddata,
        c_id,
        plan_id,
        unique_id
      );

      console.log("response in fileupload", response);
      return response;
    } catch (error) {
      console.log("the error in file upload", error);
    }
  };

  const handleFileUpload = async (filename, file) => {
    try {
      console.log("the filedata", file);
      const base64String = await convertImageToBase64(file);

      let extension = file.name.split(".");

      console.log("extension -1", extension.length - 1);
      console.log("the extension", extension[extension?.length - 1]);
      if (base64String) {
        console.log("Base64 String:", base64String);

        let pathdata = await fileuploadData(base64String, extension[extension?.length - 1]);
        console.log("pathdata", pathdata);

        console.log(
          "pathdata url",
          `${process.env.REACT_APP_SOCKET_URL}${pathdata.data.file_path}`
        );

        if (filename == "document") {
          let imgdata = `${process.env.REACT_APP_SOCKET_URL}${pathdata.data.file_path}`;
          setSelectedFile(imgdata);
          setDocFront(file.name);
          setKycPath((prevState) => ({
            ...prevState,
            docfrontpath: pathdata?.data?.file_path,
          }));

          setKycExtension((prevState) => ({
            ...prevState,
            docfrontext: extension[extension?.length - 1],
          }));
        } else if (filename == "identity") {
          let imgdata = `${process.env.REACT_APP_SOCKET_URL}${pathdata.data.file_path}`;
          setSelectedIdentityFile(imgdata);
          setIdFront(file.name);
          setKycPath((prevState) => ({
            ...prevState,
            idfrontpath: pathdata?.data?.file_path,
          }));
          setKycExtension((prevState) => ({
            ...prevState,
            idfrontext: extension[extension?.length - 1],
          }));
        } else if (filename == "documentBack") {
          let imgdata = `${process.env.REACT_APP_SOCKET_URL}${pathdata.data.file_path}`;
          setSelectedBackFile(imgdata);
          setDocBack(file.name);
          setKycPath((prevState) => ({
            ...prevState,
            docbackpath: pathdata?.data?.file_path,
          }));
          setKycExtension((prevState) => ({
            ...prevState,
            docbackext: extension[extension?.length - 1],
          }));
        } else if (filename == "identityBack") {
          let imgdata = `${process.env.REACT_APP_SOCKET_URL}${pathdata.data.file_path}`;
          setSelectedIdentityBackFile(imgdata);
          setIdBack(file.name);
          setKycPath((prevState) => ({
            ...prevState,
            idbackpath: pathdata?.data?.file_path,
          }));
          setKycExtension((prevState) => ({
            ...prevState,
            idbackext: extension[extension?.length - 1],
          }));
        }

        return base64String.toString();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleFileChange = (event, type) => {
  //   const file = event.target.files[0];

  //   const handleError = (errorType) => {
  //     if (errorType === "document") {
  //       setFileError(
  //         "Invalid file format. Please upload PDF, JPEG, JPG, PNG, or TIFF."
  //       );
  //       setSelectedFile(null);
  //     } else if (errorType === "identity") {
  //       setIdentityFileError(
  //         "Invalid file format. Please upload PDF, JPEG, JPG, PNG, or TIFF."
  //       );
  //       setSelectedIdentityFile(null);
  //     }
  //   };

  //   if (file) {
  //     // Check for valid file format
  //     if (!allowedFormats.includes(file.type)) {
  //       handleError(type);
  //     } else if (file.size > maxFileSize) {
  //       // File size validation (5MB max)
  //       if (type === "document") {
  //         setFileError("File size exceeds 5MB. Please upload a smaller file.");
  //         setSelectedFile(null);
  //       } else if (type === "identity") {
  //         setIdentityFileError(
  //           "File size exceeds 5MB. Please upload a smaller file."
  //         );
  //         setSelectedIdentityFile(null);
  //       }
  //     } else {
  //       // If everything is valid, set file and clear errors
  //       if (type === "document") {
  //         setFileError("");
  //         // setSelectedFile(file);

  //         handleFileUpload("document", file);
  //       } else if (type === "identity") {
  //         setIdentityFileError("");
  //         // setSelectedIdentityFile(file);
  //         handleFileUpload("identity", file);
  //       } else if (type === "documentBack") {
  //         setFileError("");
  //         // setSelectedBackFile(file);
  //         handleFileUpload("documentBack", file);
  //       } else if (type === "identityBack") {
  //         setIdentityFileError("");
  //         // setSelectedIdentityBackFile(file);
  //         handleFileUpload("identityBack", file);
  //       }
  //     }
  //   } else {
  //     handleError(type); // In case no file is selected
  //   }
  // };

  // const renderImagePreview = (file) => {
  //   if (file && file.type.startsWith("image/")) {
  //     const objectUrl = URL.createObjectURL(file);
  //     return (
  //       <img
  //         src={objectUrl}
  //         alt="Preview"
  //         style={{
  //           marginTop: "10px",
  //           width: "83%", // Passport-size width
  //           height: "140px", // Passport-size height
  //           objectFit: "contain", // Ensure the image fits the box without distortion
  //           borderRadius: "8px",

  //           objectPosition: "center",
  //         }}
  //       />
  //     );
  //   }
  //   return null;
  // };

  // const converttobase64 = (file)=>{
  //   const base64WithoutPrefix = file.base64.replace(
  //     /^data:application\/jpeg;base64,/,
  //     ""
  //   );
  //   return base64WithoutPrefix
  // }

  // const jpegToBase64 = async (file) =>
  //   new Promise((resolve, reject) => {
  //     console.log("file.type", file?.type);
  //     if (file?.type !== "image/jpeg") {
  //       console.log("invalid file type");
  //       return;
  //     }
  //     const reader = new FileReader();
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(file);
  //   });

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];

    const handleError = (errorType, message) => {
      if (errorType === "document") {
        setFileError(message);
        setSelectedFile(null);
      } else if (errorType === "identity") {
        setIdentityFileError(message);
        setSelectedIdentityFile(null);
      } else if (errorType === "documentBack") {
        setFileErrorBack(message);
        setSelectedBackFile(null);
      } else if (errorType === "identityBack") {
        setIdentityFileErrorBack(message);
        setSelectedIdentityBackFile(null);
      }
    };

    if (file) {
      // Check for valid file format
      if (!allowedFormats.includes(file.type)) {
        handleError(
          type,
          "Invalid file format. Please upload PDF, JPEG, JPG, PNG, or TIFF."
        );
        return;
      }

      // Check for file size (5MB max)
      if (file.size > maxFileSize) {
        handleError(
          type,
          "File size exceeds 5MB. Please upload a smaller file."
        );
        return;
      }

      // If everything is valid, proceed
      if (type === "document") {
        setFileError("");
        handleFileUpload("document", file);
      } else if (type === "identity") {
        setIdentityFileError("");
        handleFileUpload("identity", file);
      } else if (type === "documentBack") {
        setIdentityFileErrorBack("");
        handleFileUpload("documentBack", file);
      } else if (type === "identityBack") {
        setIdentityFileErrorBack("");
        handleFileUpload("identityBack", file);
      }
    } else {
      handleError(type, "No file selected. Please choose a file.");
    }
  };

  const renderImagePreview = (file) => {
    if (file) {
      // Check if file is a URL (starts with 'http' or 'https')
      const isUrl =
        typeof file === "string" &&
        (file.startsWith("http://") || file.startsWith("https://"));

      const imageSrc = isUrl ? file : URL.createObjectURL(file);

      return (
        <img
          src={imageSrc}
          alt="Preview"
          style={{
            marginTop: "10px",
            width: "83%", // Passport-size width
            height: "140px", // Passport-size height
            objectFit: "contain", // Ensure the image fits the box without distortion
            borderRadius: "8px",
            objectPosition: "center",
          }}
        />
      );
    }
    return null;
  };

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  // if (selectedFile) {
  //   let data = handleFileUpload(selectedFile);
  //   console.log("the data here", data);
  // }

  console.log("selectedBacfile", selectedBackFile);

  console.log("selectedIdentityFile", selectedIdentityFile);

  console.log("selectedIdentityBackFile", selectedIdentityBackFile);

  const settingData = async () => {
    try {
      //   console.log("the proposerData", proposerData);

      let phNo = proposerData.Proposalphonenumber;

      let kycphoto = {
        docfront: selectedFile,
        docback: selectedBackFile,
        idfront: selectedIdentityFile,
        idback: selectedIdentityBackFile,
      };

      // kyc_photo_path
      // :
      //   {
      //     "docfrontpath": "public/uploads/kyc_doc/1737441835892.png",
      //     "docbackpath": "public/uploads/kyc_doc/1737441839867.png",
      //     "idfrontpath": "public/uploads/kyc_doc/1737441844120.png",
      //     "idbackpath": "public/uploads/kyc_doc/1737441848334.jpg"
      // }

      let kycobj = {
        ...kycData,
        addressDocType: selectedDocumentDrop,
        identityDocType: selectedIDType,
        kyc_photo: kycphoto,
        kyc_photo_path: kycpath,
        kyc_extension: kycExtension,
      };

      let payloadData = {
        kyc_data: kycobj,
        phone_no: phNo,
      };

      let response = await settingReduxData(unique_id, payloadData);
      console.log("the response in set reduxdata kycpage", response);

      return response;
    } catch (error) {
      console.log("the error", error);
    }
  };

  console.log("pindodeList", pindodeList);

  console.log("kycdata in kycform", kycData);
  console.log("proposerData in kycPage", proposerData);

  useEffect(() => {
    getData();
  }, []);
  if (!apiResponse) {
    return (
      <div>
        <>
          <Redirect />
          <div className="container">
            <h1 className="mainheading">
              We Sell Happyness* <span className="middelheading">We Buy</span>
              <span className="endheading">Your Fear</span>{" "}
            </h1>
            <div className="row">
              <div className="col-md-8 ">
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="skallitonformmedical"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="skallitonform00medical"
                />

                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform1"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform2"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform3"
                />

                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform4"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform5"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform6"
                />

                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform7"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform8"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform9"
                />

                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform10"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform11"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="kycform12"
                />
              </div>
              <div className="col-md-4 ">
                <Skeleton
                  variant="text"
                  animation="wave"
                  className="Summeryskalition"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  className="summeryheadtext"
                />
                <Skeleton
                  variant="circular"
                  animation="wave"
                  className="summeryAfterFetchimages"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  className="priceandtanureSummerySkalition"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  className="skalitionSummeryText1"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  className="skalitionSummeryText2"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  className="skalitionSummeryText4"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  className="skalitionSummeryText3"
                />
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }

  if (!kycData) {
    if (!kycData?.firstname) {
      return (
        <div>
          <>
            <Redirect />
            <div className="container">
              <div className="row">
                <div className="col-lg-8 ">
                  <div className="propodsal_formsklition">
                    <div className="headersklition_propsal">
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform1"
                      />
                    </div>
                    <div className="propsal_frobody">
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform2"
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform3"
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform4"
                      />
                    </div>
                    <div className="propsal_frobody">
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform2"
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform3"
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform4"
                      />
                    </div>
                    <div className="propsal_frobody">
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform2"
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform3"
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        className="skallitonform4"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="summerySklition">
                    <div className="headerSklition">
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        className="summeryheadtext"
                      />
                    </div>
                    <div className="summerysklitonbody">
                      <Skeleton
                        variant="circular"
                        animation="wave"
                        className="summeryAfterFetchimages"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        className="priceandtanureSummerySkalition"
                      />
                    </div>
                    <div className="sklitionbody2">
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        className="skalitionSummeryText1"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        className="skalitionSummeryText1"
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        className="skalitionSummeryText1"
                      />
                    </div>

                    <div className="summeryfooterbody">
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        className="skalitionSummeryText3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      );
    }
  }

  return (
    <>
      {loader && <Loader />}
      {
        <div className="container">
          <Redirect />
          <h1 className="mainheadings kyc">
            Amount is <span className="middelheading">Just an</span>
            <span className="endheading">Number</span>{" "}
          </h1>

          {/* {error && ( */}
          {/* <Alert
              variant="filled"
              severity="error"
              style={{ width: "60%" }}
              onClose={() => {
                set_error(false, "");
              }}
            >
              {errorMessage}
            </Alert> */}
          {/* )} */}

          <br />
          <div className="row">
            <div className="col-lg-8 kycformdata">
              {c_id == "reliance" && retry_kyc && urldata && (
                <span className="kyc-error-url">
                  There is an issue with your online KYC. Kindly complete your
                  KYC manually by{" "}
                  <a href={urldata} className="kyc-error-click" target="_blank">
                    clicking here
                  </a>
                  .
                </span>
              )}

              {retry_kyc && c_id != "reliance" && (
                <div class="kyc-error-message">
                  <p>
                    There is an error in your KYC, kindly upload your documents
                    to proceed!
                  </p>
                </div>
              )}

              <div className="nextsection m-0 ">
                <h1 className="kycheader">Proposal form (step 3 of 3)</h1>

                <p className="kycTOPICS">KYC Details</p>

                <div
                  className="row ppv10 my-3"
                  style={{ paddingRight: "10px", paddingLeft: "10px" }}
                >
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <TextField
                        style={{ width: "100%" }}
                        id="standard-helperText"
                        label="First Name"
                        variant="standard"
                        required
                        // defaultValue={proposerData.ProposalName}
                        // value={
                        //   kycData?.firstname
                        //     ? kycData?.firstname
                        //     : proposerData.ProposalName.split(" ")[0]
                        // }
                        value={kycData?.firstname ? kycData?.firstname : ""}
                        // onChange={(e) => {
                        //   setFirstname(e.target.value);
                        //   if (!e.target.value && lastname) {
                        //     setFirstname("");
                        //   }
                        //   firstnamevalidate(e.target.value);
                        //   set_kyc_name("firstname", e.target.value);
                        // }}
                        onChange={(e) => {
                          validateFirstName(e.target.value);
                          setKycData((prevData) => ({
                            ...prevData,
                            firstname: e.target.value,
                          }));
                        }}
                        error={!!firstNameErr}
                        helperText={firstNameErr}
                      />
                    </div>
                  </div>

                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <TextField
                        style={{ width: "100%" }}
                        id="standard-helperText"
                        label="Middle Name"
                        variant="standard"
                        value={kycData?.middlename || ""}
                        // onChange={(e) => {
                        //   middlenamevalidate(e.target.value);
                        //   setMiddlename(e.target.value);
                        //   set_kyc_name("middlename", e.target.value);
                        // }}
                        error={!!middleNameErr}
                        helperText={middleNameErr}
                        onChange={(e) => {
                          validateMiddleName(e.target.value);
                          setKycData((prevData) => ({
                            ...prevData,
                            middlename: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <TextField
                        style={{ width: "100%" }}
                        id="standard-helperText"
                        label="Last Name"
                        variant="standard"
                        required
                        value={kycData?.lastname ? kycData?.lastname : ""}
                        onChange={(e) => {
                          validateLastName(e.target.value);
                          setKycData((prevData) => ({
                            ...prevData,
                            lastname: e.target.value,
                          }));
                        }}
                        // onChange={(e) => {
                        //   lastnamesevalidate(e.target.value);
                        //   setLastname(e.target.value);
                        //   set_kyc_name("lastname", e.target.value);
                        // }}
                        error={!!lastNameErr}
                        helperText={lastNameErr}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="row  ppv10 my-3"
                  style={{ paddingRight: "10px", paddingLeft: "10px" }}
                >
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel
                          id="demo-simple-select-standard-label"
                          style={{ fontFamily: "12pt" }}
                        >
                          Doc Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          //   value={proposalDataKyc.kycId} // Set the value to the current kycId
                          //   onChange={(e) => {
                          //     dispatch(
                          //       updateData([
                          //         { key: "kycId", value: e.target.value },
                          //       ])
                          //     );
                          //     validateDocType(e.target.value);
                          //   }}
                          //   required
                          //   error={!!doctypeValid}

                          value={kycData?.kycId || ""}
                          onChange={(e) => {
                            validateDocType(e.target.value);
                            setKycData((prevData) => ({
                              ...prevData,
                              kycId: e.target.value,
                            }));
                          }}
                        >
                          {kycOptions.map((data) => (
                            <MenuItem key={data.value} value={data.value}>
                              {data.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <span style={{ color: "red", fontSize: "9pt" }}>
                        {/* {doctypeValid} */}
                        {docTypeErr && (
                          <p className="error-message red-text">{docTypeErr}</p>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DesktopDatePicker"]}>
                          <DesktopDatePicker
                            style={{ width: "100%" }}
                            variant="standard"
                            id="standard-helperText"
                            label="DOB"
                            // defaultValue={dayjs(proposalData.KycDateOfbirth)}
                            // value={
                            //   proposalData.KycDateOfbirth
                            //     ? dayjs(proposalData.KycDateOfbirth)
                            //     : ""
                            // }
                            // onChange={(e) => {
                            //   validateDob(e);
                            //   dispatch(
                            //     updateData([
                            //       { key: "KycDateOfbirth", value: getDate(e) },
                            //     ])
                            //   );
                            // }}

                            value={
                              kycData?.KycDateOfbirth &&
                              dayjs(kycData?.KycDateOfbirth)
                                ? kycData?.KycDateOfbirth &&
                                  dayjs(kycData?.KycDateOfbirth)
                                : ""
                            }
                            onChange={(e) => {
                              validateDob(e);
                              setKycData((prevData) => ({
                                ...prevData,
                                KycDateOfbirth: getDate(e),
                              }));
                            }}
                            format="DD-MM-YYYY"
                            required
                            // error={!!dobError}
                            // helperText={dobError}
                            sx={{
                              "& .MuiInputBase-root": {
                                boxShadow: "none",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                              },
                              "& .MuiInputBase-input": {
                                padding: "10px 5px", // Adjust padding as needed
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {dobErr && (
                        <p className="error-message red-text">{dobErr}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <TextField
                        style={{ width: "100%" }}
                        id="standard-helperText"
                        label="Doc Number"
                        defaultValue=""
                        variant="standard"
                        // value={
                        //   proposalData.cardNum
                        //     ? proposalData.cardNum.toUpperCase()
                        //     : ""
                        // }
                        error={!!docNumErr}
                        helperText={docNumErr}
                        value={kycData?.cardNum || ""}
                        onChange={(e) => {
                          validateDocNumber(e.target.value);
                          setKycData((prevData) => ({
                            ...prevData,
                            cardNum: e.target.value,
                          }));
                        }}
                        required
                        // onChange = {e => {dispatch(updateData([{key:"cardNum",value:e.target.value}]))}}
                        // onChange={(e) => {
                        //   const docNumberValue = e.target.value;
                        //   const docTypeValue = proposalData.kycId;
                        //   dispatch(
                        //     updateData([
                        //       { key: "cardNum", value: docNumberValue.toUpperCase() },
                        //     ])
                        //   );
                        //   validateDocNumber(docNumberValue, docTypeValue);
                        // }}
                        // error={!!docNumberError}
                        // helperText={docNumberError}
                      />
                    </div>
                    {/* {docNumErr && (
                        <p className="error-message red-text">{docNumErr}</p>
                      )} */}
                  </div>
                </div>

                <div className="col-md px-0 py-2">
                  {/* <div className="prpfbd">
        <TextField
          type="file"
          variant="outlined"
          fullWidth
          onChange={(e) => handleFileChange(e, "document")}
          helperText={fileError || (selectedFile ? `Selected File: ${selectedFile.name}` : "")}
          error={Boolean(fileError)}
        />
      </div>

      <div className="prpfbd" style={{ marginTop: "16px" }}>
        <TextField
          type="file"
          variant="outlined"
          fullWidth
          onChange={(e) => handleFileChange(e, "identity")}
          helperText={identityFileError || (selectedIdentityFile ? `Selected File: ${selectedIdentityFile.name}` : "")}
          error={Boolean(identityFileError)}
        />
      </div> */}
                  {retry_kyc && c_id != "reliance" && (
                    <>
                      <div class="line-container">
                        <hr class="kychr" />
                        <p class="kycseparator">Document Upload</p>
                        <hr class="kychr" />
                      </div>

                      <Box className="col-md px-0 py-2">
                        <Box display="flex" flexDirection="column" gap="16px">
                          {/* Document Upload Section (Address Proof) */}
                          <Box mb={3}>
                            {/* Title and Document List */}
                            <Typography
                              variant="h6"
                              // fontWeight="bold"
                              sx={{
                                color: "#33333391",
                                marginBottom: "15px",
                                paddingLeft: "5px",
                                fontWeight: "500",
                                fontSize: "17px",

                                width: "fit-content",
                                /* border: 2px solid #124cb185; */
                                padding: "5px",
                                borderRadius: "5px",
                                color: "#236fc0",
                                backgroundColor: "aliceblue",
                              }}
                            >
                              Address Proof
                            </Typography>

                            {/* <Typography variant="body2" sx={{ color: '#555', marginBottom: '16px' }}>
        Voter ID, Driving License, Passport
      </Typography> */}

                            <Box display="flex" flexDirection="row">
                              <Box
                                display="flex"
                                flexWrap="wrap"
                                className="col-md-12"
                                sx={{
                                  paddingLeft: "0px",
                                }}
                              >
                                <FormControl
                                  variant="standard"
                                  className="col-md-4"
                                >
                                  <InputLabel
                                    id="select-id-type-label"
                                    style={{ fontFamily: "12pt" }}
                                  >
                                    Select Document
                                  </InputLabel>
                                  <Select
                                    labelId="select-id-type-label"
                                    id="select-id-type"
                                    value={selectedDocumentDrop || ""}
                                    onChange={(e) => {
                                      handleChangeDrop(e);
                                      // setKycData((prevData) => ({
                                      //   ...prevData,
                                      //   addressDoc: e.target.value,
                                      // }));
                                    }}
                                    sx={{ color: "#555" }}
                                    displayEmpty // This allows displaying an empty value by default
                                  >
                                    {/* Placeholder item */}
                                    {/* <MenuItem value="" disabled>
                                  Select ID
                                 </MenuItem> */}

                                    <MenuItem value="VOTERID">
                                      Voter ID
                                    </MenuItem>
                                    <MenuItem value="DL">
                                      Driving License
                                    </MenuItem>
                                    <MenuItem value="PASSPORT">
                                      Passport
                                    </MenuItem>
                                    <MenuItem value="AADHAAR">Adhaar</MenuItem>
                                    <MenuItem value="PAN">Pan</MenuItem>

                                    <MenuItem value="OTHER">Other</MenuItem>
                                  </Select>
                                </FormControl>

                                {/* Front and Back Document Upload Section (Horizontal Layout) */}
                                {/* Front Document Upload */}
                                <Box
                                  flex="1"
                                  className="col-md-4"

                                  // minWidth="250px"
                                >
                                  {/* <Typography
                                variant="subtitle1"
                                fontWeight="medium"
                                sx={{
                                  color: "#333",
                                  marginBottom: "8px",
                                  color: "#33333399",
                                  paddingLeft: "5px",
                                }}
                              >
                                {selectedFile
                                  ? `Uploaded Document (Front): ${selectedFile.name}`
                                  : "Upload Document (Front)"}
                              </Typography> */}
                                  <Button
                                    variant="contained"
                                    component="label"
                                    fullWidth
                                    sx={{
                                      marginTop: "8px",
                                      textTransform: "none",
                                      // padding: "10px 16px",
                                      padding: "5px",
                                      borderRadius: "8px",
                                      backgroundColor: "#2196f3", // Blue button
                                      color: "white", // White text color
                                      "&:hover": {
                                        backgroundColor: "#1976d2", // Darker blue on hover
                                      },

                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {selectedFile
                                      ? "Change Document (Front)"
                                      : "Upload Document (Front)"}
                                    <input
                                      type="file"
                                      hidden
                                      accept=".pdf, .jpeg, .jpg, .png, .tiff, .tif"
                                      onChange={(e) =>
                                        handleFileChange(e, "document")
                                      }
                                    />
                                  </Button>
                                  {fileError && (
                                    <Typography
                                      color="error"
                                      sx={{
                                        marginTop: "8px",
                                        fontSize: "0.875em",
                                      }}
                                    >
                                      {fileError}
                                    </Typography>
                                  )}
                                  {/* {renderImagePreview(selectedFile)}{" "} */}
                                  {selectedFile && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={1}
                                      style={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: " space-between",
                                      }}
                                    >
                                      {renderImagePreview(selectedFile)}
                                      <Button
                                        onClick={() => {
                                          setSelectedFile("");
                                          setDocFront("");
                                          setKycPath((prevState) => ({
                                            ...prevState,
                                            docfrontpath: "",
                                          }));
                                          setKycExtension((prevState) => ({
                                            ...prevState,
                                            docfrontext: "",
                                          }));
                                        }}
                                        sx={{
                                          marginLeft: "8px",
                                          backgroundColor: "#e80808d1",
                                          borderRadius: "50%",
                                          // minWidth: "32px",
                                          width: "24px",
                                          minWidth: "15px",
                                          height: "22px",
                                          color: "white",
                                          "&:hover": {
                                            backgroundColor: "#e80808d1", // Keep the same color on hover
                                          },
                                        }}
                                      >
                                        ✖
                                      </Button>
                                    </Box>
                                  )}
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="medium"
                                    sx={{
                                      color: "#333",
                                      marginBottom: "8px",
                                      color: "#33333399",
                                      paddingLeft: "5px",
                                      // textAlign:"center"
                                    }}
                                  >
                                    {docFront ? `${docFront}` : ""}
                                  </Typography>
                                  {/* Image Preview for Front */}
                                </Box>

                                {/* Back Document Upload */}
                                <Box
                                  flex="1"
                                  className="col-md-4"

                                  //  minWidth="250px"
                                >
                                  {/* <Typography
                                variant="subtitle1"
                                fontWeight="medium"
                                sx={{
                                  color: "#333",
                                  marginBottom: "8px",
                                  color: "#33333399",
                                  paddingLeft: "5px",
                                }}
                              >
                                {selectedBackFile
                                  ? `Uploaded Document (Back): ${selectedBackFile.name}`
                                  : "Upload Document (Back)"}
                              </Typography> */}
                                  <Button
                                    variant="contained"
                                    component="label"
                                    fullWidth
                                    sx={{
                                      marginTop: "8px",
                                      textTransform: "none",
                                      // padding: "10px 16px",
                                      padding: "5px",
                                      borderRadius: "8px",
                                      backgroundColor: "#2196f3", // Blue button
                                      color: "white", // White text color
                                      "&:hover": {
                                        backgroundColor: "#1976d2", // Darker blue on hover
                                      },
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {selectedBackFile
                                      ? "Change Document (Back)"
                                      : "Upload Document (Back)"}
                                    <input
                                      type="file"
                                      hidden
                                      accept=".pdf, .jpeg, .jpg, .png, .tiff, .tif"
                                      onChange={(e) =>
                                        handleFileChange(e, "documentBack")
                                      }
                                    />
                                  </Button>
                                  {fileErrorBack && (
                                  <Typography
                                    color="error"
                                    sx={{
                                      marginTop: "8px",
                                      fontSize: "0.875em",
                                    }}
                                  >
                                    {fileErrorBack}
                                  </Typography>
                                    )}
                                  {/* {renderImagePreview(selectedBackFile)}{" "} */}
                                  {selectedBackFile && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={1}
                                      style={{
                                        display: "flex",
                                        alignItems: "start",

                                        justifyContent: " space-between",
                                      }}
                                    >
                                      {renderImagePreview(selectedBackFile)}
                                      <Button
                                        onClick={() => {
                                          setSelectedBackFile("");
                                          setDocBack("");
                                          setKycPath((prevState) => ({
                                            ...prevState,
                                            docbackpath: "",
                                          }));
                                          setKycExtension((prevState) => ({
                                            ...prevState,
                                            docbackext: "",
                                          }));
                                        }}
                                        sx={{
                                          marginLeft: "8px",
                                          backgroundColor: "#e80808d1",
                                          borderRadius: "50%",
                                          // minWidth: "32px",
                                          width: "24px",
                                          minWidth: "15px",
                                          height: "22px",
                                          color: "white",
                                          "&:hover": {
                                            backgroundColor: "#e80808d1", // Keep the same color on hover
                                          },
                                        }}
                                      >
                                        ✖
                                      </Button>
                                    </Box>
                                  )}
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="medium"
                                    sx={{
                                      color: "#333",
                                      marginBottom: "8px",
                                      color: "#33333399",
                                      paddingLeft: "5px",
                                      // textAlign:"center"
                                    }}
                                  >
                                    {docBack ? `${docBack}` : ""}
                                  </Typography>
                                  {/* Image Preview for Back */}
                                </Box>
                              </Box>
                            </Box>
                          </Box>

                          {/* Identity Proof Upload Section */}
                          <Box mb={3}>
                            {/* Title and Document List for Identity Proof */}
                            <Typography
                              variant="h6"
                              // fontWeight="bold"
                              sx={{
                                color: "#33333391",
                                marginBottom: "15px",
                                paddingLeft: "5px",
                                fontWeight: "500",
                                fontSize: "17px",
                                width: "fit-content",
                                /* border: 2px solid #124cb185; */
                                padding: "5px",
                                borderRadius: "5px",
                                color: "#236fc0",
                                backgroundColor: "aliceblue",
                              }}
                            >
                              Identity Proof
                            </Typography>
                            {/* <Typography
                          variant="body2"
                          sx={{ color: "#555", marginBottom: "16px" }}
                        >
                          PAN, Voter ID, Driving License, Passport
                        </Typography> */}
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              className="col-md-12"
                              sx={{
                                paddingLeft: "0px",
                              }}
                            >
                              <FormControl
                                variant="standard"
                                className="col-md-4"
                              >
                                <InputLabel
                                  id="select-id-type-label"
                                  style={{ fontFamily: "12pt" }}
                                >
                                  Select ID
                                </InputLabel>
                                <Select
                                  labelId="select-id-type-label"
                                  id="select-id-type"
                                  value={selectedIDType || ""}
                                  onChange={(e) => {
                                    handleChange(e);
                                    // setKycData((prevData) => ({
                                    //   ...prevData,
                                    //   identityDoc: e.target.value,
                                    // }));
                                  }}
                                  sx={{ color: "#555" }}
                                  displayEmpty // This allows displaying an empty value by default
                                >
                                  {/* Placeholder item */}
                                  {/* <MenuItem value="" disabled>
      Select ID
    </MenuItem> */}

                                  <MenuItem value="VOTERID">Voter ID</MenuItem>
                                  <MenuItem value="DL">
                                    Driving License
                                  </MenuItem>
                                  <MenuItem value="PASSPORT">Passport</MenuItem>
                                  <MenuItem value="AADHAAR">Adhaar</MenuItem>
                                  <MenuItem value="PAN">Pan</MenuItem>

                                  <MenuItem value="OTHER">Other</MenuItem>
                                </Select>
                              </FormControl>

                              {/* Front and Back Identity Upload Section (Horizontal Layout) */}
                              {/* <Box display="flex" gap="16px" flexWrap="wrap"> */}
                              {/* Front Identity Upload */}
                              <Box
                                flex="1"
                                className="col-md-4"

                                // minWidth="250px"
                              >
                                {/* <Typography
                              variant="subtitle1"
                              fontWeight="medium"
                              sx={{ color: "#33333399", marginBottom: "8px" }}
                            >
                              {selectedIdentityFile
                                ? `Uploaded Identity Proof (Front): ${selectedIdentityFile.name}`
                                : "Upload Identity Proof (Front)"}
                            </Typography> */}
                                <Button
                                  variant="contained"
                                  component="label"
                                  fullWidth
                                  sx={{
                                    marginTop: "8px",
                                    textTransform: "none",
                                    // padding: "10px 16px",
                                    borderRadius: "8px",
                                    backgroundColor: "#2196f3", // Blue button
                                    color: "white", // White text color
                                    "&:hover": {
                                      backgroundColor: "#1976d2", // Darker blue on hover
                                    },
                                  }}
                                >
                                  {selectedIdentityFile
                                    ? "Change Identity (Front)"
                                    : "Upload Identity (Front)"}
                                  <input
                                    type="file"
                                    hidden
                                    accept=".pdf, .jpeg, .jpg, .png, .tiff, .tif"
                                    onChange={(e) =>
                                      handleFileChange(e, "identity")
                                    }
                                  />
                                </Button>
                                {identityFileError && (
                                  <Typography
                                    color="error"
                                    sx={{
                                      marginTop: "8px",
                                      fontSize: "0.875em",
                                    }}
                                  >
                                    {identityFileError}
                                  </Typography>
                                )}
                                {/* {renderImagePreview(selectedIdentityFile)}{" "} */}

                                {selectedIdentityFile && (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mt={1}
                                    style={{
                                      display: "flex",
                                      justifyContent: " space-between",
                                      alignItems: "start",
                                    }}
                                  >
                                    {renderImagePreview(selectedIdentityFile)}
                                    <Button
                                      onClick={() => {
                                        setSelectedIdentityFile("");
                                        setIdFront("");
                                        setKycPath((prevState) => ({
                                          ...prevState,
                                          idfrontpath: "",
                                        }));
                                        setKycExtension((prevState) => ({
                                          ...prevState,
                                          idfrontext: "",
                                        }));
                                      }}
                                      sx={{
                                        marginLeft: "8px",
                                        backgroundColor: "#e80808d1",
                                        borderRadius: "50%",
                                        // minWidth: "32px",
                                        width: "24px",
                                        minWidth: "15px",
                                        height: "22px",
                                        color: "white",
                                        "&:hover": {
                                          backgroundColor: "#e80808d1", // Keep the same color on hover
                                        },
                                      }}
                                    >
                                      ✖
                                    </Button>
                                  </Box>
                                )}
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="medium"
                                  sx={{
                                    color: "#333",
                                    marginBottom: "8px",
                                    color: "#33333399",
                                    paddingLeft: "5px",
                                    // textAlign:"center"
                                  }}
                                >
                                  {idFront ? `${idFront}` : ""}
                                </Typography>
                                {/* Image Preview for Front */}
                              </Box>

                              {/* Back Identity Proof Upload */}
                              <Box
                                flex="1"
                                className="col-md-4"

                                //  minWidth="250px"
                              >
                                {/* <Typography
                              variant="subtitle1"
                              fontWeight="medium"
                              sx={{ color: "#33333399", marginBottom: "8px" }}
                            >
                              {selectedIdentityBackFile
                                ? `Uploaded Identity Proof (Back): ${selectedIdentityBackFile.name}`
                                : "Upload Identity Proof (Back)"}
                            </Typography> */}
                                <Button
                                  variant="contained"
                                  component="label"
                                  fullWidth
                                  sx={{
                                    marginTop: "8px",
                                    textTransform: "none",
                                    // padding: "10px 16px",
                                    padding: "5px",
                                    borderRadius: "8px",
                                    backgroundColor: "#2196f3", // Blue button
                                    color: "white", // White text color
                                    "&:hover": {
                                      backgroundColor: "#1976d2", // Darker blue on hover
                                    },
                                  }}
                                >
                                  {selectedIdentityBackFile
                                    ? "Change Identity (Back)"
                                    : "Upload Identity (Back)"}
                                  <input
                                    type="file"
                                    hidden
                                    accept=".pdf, .jpeg, .jpg, .png, .tiff, .tif"
                                    onChange={(e) =>
                                      handleFileChange(e, "identityBack")
                                    }
                                  />
                                </Button>
                                {identityFileErrorBack && (
                                  <Typography
                                    color="error"
                                    sx={{
                                      marginTop: "8px",
                                      fontSize: "0.875em",
                                    }}
                                  >
                                    {identityFileErrorBack}
                                  </Typography>
                                )}
                                {/* {renderImagePreview(selectedIdentityBackFile)}{" "} */}
                                {selectedIdentityBackFile && (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mt={1}
                                    style={{
                                      display: "flex",
                                      justifyContent: " space-between",
                                      alignItems: "start",
                                    }}
                                  >
                                    {renderImagePreview(
                                      selectedIdentityBackFile
                                    )}
                                    <Button
                                      onClick={() => {
                                        setSelectedIdentityBackFile("");
                                        setIdBack("");
                                        setKycPath((prevState) => ({
                                          ...prevState,
                                          idbackpath: "",
                                        }));
                                        setKycExtension((prevState) => ({
                                          ...prevState,
                                          idbackext: "",
                                        }));
                                      }}
                                      // background-color: lightgrey;
                                      // border-radius: 50%;
                                      // min-width: 32px;
                                      // height: 29px;

                                      sx={{
                                        marginLeft: "8px",
                                        backgroundColor: "#e80808d1",
                                        borderRadius: "50%",
                                        // minWidth: "32px",
                                        // minWidth: "15px",
                                        width: "24px",
                                        minWidth: "15px",

                                        height: "22px",
                                        color: "white",
                                        "&:hover": {
                                          backgroundColor: "#e80808d1", // Keep the same color on hover
                                        },
                                      }}
                                    >
                                      ✖
                                    </Button>
                                  </Box>
                                )}
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="medium"
                                  sx={{
                                    color: "#333",
                                    marginBottom: "8px",
                                    color: "#33333399",
                                    paddingLeft: "5px",
                                    // textAlign:"center"
                                  }}
                                >
                                  {idback ? `${idback}` : ""}
                                </Typography>
                                {/* Image Preview for Back */}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </div>
                <div className="row col-md-4  ppv10 my-3">
                  {c_id == "hdfc" ? (
                    <div className="col px-0">
                      <div className="prpfbd">
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            Relationship With Nominee
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            onChange={(e) => {
                              // validateNomineeRelation(e.target.value);
                              // dispatch(
                              //   updateData([
                              //     {
                              //       key: "RelationWithNominee",
                              //       value: e.target.value,
                              //     },
                              //   ])
                              // );
                              setKycData((prevData) => ({
                                ...prevData,
                                RelationWithNominee: e.target.value,
                              }));
                            }}
                            value={proposerData.RelationWithNominee}
                            // error={!!nomRel}
                            required
                          >
                            {HdfcrelationshipOptions.map((data) => {
                              return (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {/* <span style={{ color: "red", fontSize: "9pt" }}>
                          {nomRel}
                        </span> */}
                      </div>
                    </div>
                  ) : null}
                </div>
                <p className="kycTOPICS">Nominee Details</p>

                <div
                  className="row  ppv10 my-3"
                  style={{ paddingRight: "10px", paddingLeft: "10px" }}
                >
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <TextField
                        style={{ width: "100%" }}
                        id="standard-helperText"
                        label="Full Name"
                        defaultValue=""
                        variant="standard"
                        value={kycData?.nomineeName || ""}
                        onChange={(e) => {
                          validateFullName(e.target.value);
                          setKycData((prevData) => ({
                            ...prevData,
                            nomineeName: e.target.value,
                          }));
                        }}
                        // value={proposalData.nomineeName}
                        required
                        // onChange={(e) => {
                        //   validateFullName(e.target.value);
                        //   dispatch(
                        //     updateData([
                        //       { key: "nomineeName", value: e.target.value },
                        //     ])
                        //   );
                        // }}
                        error={!!nomNameErr}
                        helperText={nomNameErr}
                      />
                    </div>
                  </div>
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DesktopDatePicker"]}>
                          <DesktopDatePicker
                            style={{ width: "100%" }}
                            variant="standard"
                            id="standard-helperText"
                            label="DOB"
                            value={
                              (kycData?.nomineeDateOfbirth &&
                                dayjs(kycData?.nomineeDateOfbirth)) ||
                              ""
                            }
                            onChange={(e) => {
                              validateDobNom(e);
                              setKycData((prevData) => ({
                                ...prevData,
                                nomineeDateOfbirth: getDate(e),
                              }));
                            }}
                            // defaultValue={
                            //   proposalData.nomineeDateOfbirth
                            //     ? dayjs(proposalData.nomineeDateOfbirth)
                            //     : ""
                            // }
                            // value={
                            //   proposalData.nomineeDateOfbirth
                            //     ? dayjs(proposalData.nomineeDateOfbirth)
                            //     : ""
                            // }
                            // onChange={(e) => {
                            //   validateDobNominee(e);
                            //   dispatch(
                            //     updateData([
                            //       {
                            //         key: "nomineeDateOfbirth",
                            //         value: getDate(e),
                            //       },
                            //     ])
                            //   );
                            // }}
                            format="DD-MM-YYYY"
                            required
                            // error={!!nomDobErr}
                            // helperText={nomDobErr}
                            sx={{
                              "& .MuiInputBase-root": {
                                boxShadow: "none",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                              },
                              "& .MuiInputBase-input": {
                                padding: "10px 5px", // Adjust padding as needed
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {nomDobErr && (
                        <p className="error-message red-text">{nomDobErr}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <FormControl variant="standard" sx={{ width: "90%" }}>
                        <InputLabel
                          id="demo-simple-select-standard-label"
                          style={{ fontSize: "14px" }}
                        >
                          Relationship With Proposer
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="Age"
                          value={kycData?.nomineerelationship || ""}
                          onChange={(e) => {
                            validateRelationship(e.target.value);
                            setKycData((prevData) => ({
                              ...prevData,
                              nomineerelationship: e.target.value,
                            }));
                          }}

                          //   value={proposalDataKyc.nomineerelationship}
                          //   onChange={(e) => {
                          //     dispatch(
                          //       updateData([
                          //         {
                          //           key: "nomineerelationship",
                          //           value: e.target.value,
                          //         },
                          //       ])
                          //     );
                          //     validateRelationship(e.target.value);
                          //   }}
                          //   error={!!relationValid}
                        >
                          {c_id === "reliance"
                            ? relincenomine.map((data, i) => (
                                <MenuItem key={data.value} value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))
                            : c_id == "hdfc"
                            ? hdfcnomine.map((data, i) => (
                                <MenuItem key={data.value} value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))
                            : OtherNominie.map((data, i) => (
                                <MenuItem key={data.value} value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>

                      {/* <span style={{ color: "red", fontSize: "9pt" }}>
                        {relationValid}
                      </span> */}
                      {nomRelErr && (
                        <p className="error-message red-text">{nomRelErr}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="row ppv10 my-3 col-md-12 justify-content-center"
                  style={{ paddingRight: "10px", paddingLeft: "10px" }}
                >
                  <div className="col px-0">
                    {c_id == "reliance" && (
                      <div className="prpfbd" style={{ width: "30%" }}>
                        <TextField
                          id="standard-helperText"
                          label="Nominee Phone Number"
                          defaultValue=""
                          variant="standard"
                          value={kycData?.nomineePhoneNumber || ""}
                          onChange={(e) => {
                            validateNomPhoneNumber(e.target.value);
                            setKycData((prevData) => ({
                              ...prevData,
                              nomineePhoneNumber: e.target.value,
                            }));
                          }}
                          //   value={proposalData.nomineePhoneNumber}
                          //   required
                          //   onChange={(e) => {
                          //     validatePhoneNumber(e.target.value);
                          //     dispatch(
                          //       updateData([
                          //         {
                          //           key: "nomineePhoneNumber",
                          //           value: e.target.value,
                          //         },
                          //       ])
                          //     );
                          //   }}
                          error={!!nomPhoneError}
                          helperText={nomPhoneError}
                          style={{ width: "100%" }}
                        />
                      </div>
                    )}
                    <div className="prpfbd">
                      <TextField
                        id="standard-helperText"
                        label="Address"
                        defaultValue=""
                        variant="standard"
                        // value={proposalData.nomineeAddress}
                        required
                        value={kycData?.nomineeAddress || ""}
                        onChange={(e) => {
                          validateNomAddress(e.target.value);
                          setKycData((prevData) => ({
                            ...prevData,
                            nomineeAddress: e.target.value,
                          }));
                        }}
                        // onChange={(e) => {
                        //   validateAddress(e.target.value);
                        //   dispatch(
                        //     updateData([
                        //       { key: "nomineeAddress", value: e.target.value },
                        //     ])
                        //   );
                        // }}
                        error={!!nomAddressError}
                        helperText={nomAddressError}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>

                <p className="kycTOPICS">Communication Details</p>

                <div
                  className="row ppv10 my-3"
                  style={{ paddingRight: "10px", paddingLeft: "10px" }}
                >
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <TextField
                        style={{ width: "100%" }}
                        id="standard-helperText"
                        label="Mobile Number"
                        // defaultValue={proposalData.Proposalphonenumber}
                        variant="standard"
                        disabled
                        value={proposerData?.Proposalphonenumber || ""}

                        // required
                        // // onChange={e => {
                        // //     dispatch(updateData([{ key: "Proposalphonenumber", value: e.target.value }]));
                        // //     validateMobileNumber(e.target.value);
                        // //         }}
                        // error={Boolean(mobileNumberError)}
                        // helperText={mobileNumberError}
                      />
                    </div>
                  </div>
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <TextField
                        style={{ width: "100%" }}
                        type="text"
                        id="standard-helperText"
                        label="Email Id"
                        defaultValue=""
                        variant="standard"
                        value={kycData?.Email || ""}
                        onChange={(e) => {
                          validateEmail(e.target.value);
                          setKycData((prevData) => ({
                            ...prevData,
                            Email: e.target.value,
                          }));
                        }}
                        // value={proposalData.Email}
                        // onChange={(e) => {
                        //   dispatch(
                        //     updateData([
                        //       { key: "Email", value: e.target.value },
                        //     ])
                        //   );
                        //   validateEmail(e.target.value);
                        // }}
                        error={!!emailError}
                        helperText={emailError}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <TextField
                        style={{ width: "100%" }}
                        id="standard-helperText"
                        label="Address"
                        defaultValue=""
                        variant="standard"
                        // value={proposalData.street}
                        required
                        value={kycData?.street || ""}
                        onChange={(e) => {
                          validateStreet(e.target.value);
                          setKycData((prevData) => ({
                            ...prevData,
                            street: e.target.value,
                          }));
                        }}
                        // onChange={(e) => {
                        //   dispatch(
                        //     updateData([
                        //       { key: "street", value: e.target.value },
                        //     ])
                        //   );
                        //   validateKycaddress(e.target.value);
                        // }}
                        // error={Boolean(kycAddressError)}
                        // helperText={kycAddressError}
                      />
                    </div>

                    {kycAddressError && (
                      <p className="error-message red-text">
                        {kycAddressError}
                      </p>
                    )}
                  </div>
                </div>

                {/* New Cal-md-12 Div start Hear */}

                <div
                  className="row ppv10 my-3"
                  style={{ paddingRight: "10px", paddingLeft: "10px" }}
                >
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      <FormControl fullWidth>
                        <TextField
                          style={{ width: "100%" }}
                          id="standard-helperText"
                          label="State"
                          // value={proposalData.Sate}
                          value={
                            (proposerData?.Sate && proposerData.Sate) || ""
                          }
                          variant="standard"
                          required
                          disabled
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      {/* <TextField
                        style={{ width: "100%" }}
                        id="standard-helperText"
                        label="District"
                        value={proposerData?.District && proposerData.District ||  ""}
                        variant="standard"
                        required
                        // onChange={(e) =>
                        //   dispatch(
                        //     updateData([
                        //       { key: "District", value: e.target.value },
                        //     ])
                        //   )
                        // }
                        disabled
                      /> */}
                      <FormControl fullWidth>
                        <TextField
                          style={{ width: "100%" }}
                          id="standard-helperText"
                          label="State"
                          value={proposerData?.District || ""}
                          variant="standard"
                          required
                          disabled
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md px-0 py-2">
                    <div className="prpfbd">
                      {console.log(
                        "pincode list",
                        pinCodeList.find(
                          (option) => option.label == proposerData.PinCode
                        ).label
                      )}
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        // Using defaultValue to set the initial value
                        // defaultValue={
                        //   pinCodeList.find(
                        //     (option) => option.label == proposerData.PinCode
                        //   ).label
                        // }
                        value={kycData?.PinCode ? kycData?.PinCode : ""}
                        options={pinCodeList || []}
                        sx={{
                          width: 300,
                          "& .MuiInputBase-root": {
                            boxShadow: "none",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                          },
                          "& .MuiInputBase-input": {
                            padding: "10px 5px", // Adjust padding as needed
                          },
                        }}
                        onChange={(event, e) => {
                          // Update the PinCode in state on selection change
                          validatePinCode(e.label);
                          setKycData((prevData) => ({
                            ...prevData,
                            PinCode: e?.label || "", // Set PinCode based on selected label
                          }));
                        }}
                        required
                        renderInput={(params) => (
                          <TextField {...params} label="Pincode" />
                        )}
                        style={{
                          width: "70%",
                          textOverflow: "unset",
                          minWidth: "60px",
                        }}
                      />

                      <>
                        <p
                          style={{
                            color: " #d32f2f",
                            fontWeight: "400",
                            fontSize: "13px",
                          }}
                        >
                          {/* {pincodeError} */}
                          {pinError && (
                            <p className="error-message red-text">{pinError}</p>
                          )}
                        </p>
                      </>
                    </div>
                  </div>
                </div>

                {/* -------------------------------------------------------------------------------------------------------------  */}

                {["hdfc", "care"].includes(c_id) && (
                  <>
                    <p className="kycTOPICS">Bank Details</p>

                    <div
                      className="row ppv10 my-3"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }}
                    >
                      <div className="col-md-6 px-0 py-2">
                        <div className="prpfbd">
                          <TextField
                            style={{ width: "100%" }}
                            type="text"
                            id="standard-helperText"
                            label="Name"
                            // defaultValue={proposalData.Name}
                            variant="standard"
                            value={kycData?.Name || ""}
                            onChange={(e) => {
                              setKycData((prevData) => ({
                                ...prevData,
                                Name: e.target.value,
                              }));
                              validateName(e.target.value);
                            }}
                            // value={proposalData.Email}
                            // onChange={(e) => {
                            //   dispatch(
                            //     updateData([
                            //       { key: "Name", value: e.target.value },
                            //     ])
                            //   );
                            //   validateName(e.target.value);
                            // }}
                            error={nameError}
                            helperText={nameError}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 px-0 py-2">
                        <div className="prpfbd">
                          <TextField
                            style={{ width: "100%" }}
                            type="text"
                            id="standard-helperText"
                            label="Account Number"
                            // defaultValue={proposalData.accountNumber}
                            variant="standard"
                            value={kycData?.accountNumber || ""}
                            onChange={(e) => {
                              setKycData((prevData) => ({
                                ...prevData,
                                accountNumber: e.target.value,
                              }));
                              validateAccountNumber(e.target.value);
                            }}
                            // value={proposalData.Email}
                            // onChange={(e) => {
                            //   dispatch(
                            //     updateData([
                            //       {
                            //         key: "accountNumber",
                            //         value: e.target.value,
                            //       },
                            //     ])
                            //   );
                            //   validateAccountNumber(e.target.value);
                            // }}
                            error={accountError}
                            helperText={accountError}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 px-0 py-2">
                        <div className="prpfbd">
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: 120, width: " 100% " }}
                            error={bankNameError}
                            helperText={bankNameError}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Bank Name
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              // defaultValue={proposalData.bankName}
                              // value={bankNameData}
                              // onChange={(event)=>{handleChange(event);
                              //   dispatch(
                              //     updateData([
                              //       { key: "bankName", value: event.target.value },
                              //     ])
                              //   );
                              //   validateBankName(event.target.value);

                              // }}
                              value={kycData?.bankName || ""}
                              onChange={(e) => {
                                setKycData((prevData) => ({
                                  ...prevData,
                                  bankName: e.target.value,
                                }));
                                validateBankName(e.target.value);
                              }}
                              label="Bank Name"
                            >
                              {bankNames.map((bank) => (
                                <MenuItem
                                  key={bank.BankCode}
                                  value={bank.BankName}
                                >
                                  {bank.BankName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      <div className="col-md-6 px-0 py-2">
                        <div className="prpfbd">
                          <TextField
                            style={{ width: "100%" }}
                            type="text"
                            id="standard-helperText"
                            label="IFSC Code"
                            //   defaultValue={proposalData.ifscCode}
                            variant="standard"
                            value={kycData?.ifscCode || ""}
                            onChange={(e) => {
                              setKycData((prevData) => ({
                                ...prevData,
                                ifscCode: e.target.value,
                              }));
                              validateIFSCCode(e.target.value);
                            }}
                            // value={proposalData.Email}
                            //   onChange={(e) => {
                            //     dispatch(
                            //       updateData([
                            //         { key: "ifscCode", value: e.target.value },
                            //       ])
                            //     );
                            //     validateIFSCCode(e.target.value);
                            //   }}
                            error={ifscError}
                            helperText={ifscError}
                            required
                          />
                        </div>
                      </div>

                      <div
                        className="col-md-4 px-0 py-2"
                        style={{ marginTop: "-2px" }}
                      >
                        <div className="prpfbd">
                          {/* <TextField
                              style={{ width: "100%" }}
                              type="text"
                              id="standard-helperText"
                              label="Physical Dispatch"
                              defaultValue=""
                              variant="standard"
                              value={proposalData.Email}
                              onChange={(e) => {
                                dispatch(
                                  updateData([
                                    {
                                      key: "physicalDispatch",
                                      value: e.target.value,
                                    },
                                  ])
                                );
                                validateEmail(e.target.value);
                              }}
                              error={emailError}
                              helperText={emailError}
                              required
                            /> */}
                          {/* <FormControl
                              onChange={(e) => {
                                dispatch(
                                  updateData([
                                    {
                                      key: "phyDispatch",
                                      value: e.target.value,
                                    },
                                  ])
                                );
                                validatePhysicalDispatch(e.target.value);
                              }}
                              error={phyError}
                              helperText={phyError}
                              

                            >
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                style={{ marginBottom: "0px" }}

                              >
                                Physical Dispatch
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                              value={proposalData.phyDispatch || ""}

                              >
                                <FormControlLabel
                                  value="yes"
                                  control={<Radio />}
                                  label="yes"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<Radio />}
                                  label="no"
                                />
                              </RadioGroup>
                            </FormControl> */}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div></div>
            </div>

            <div className="col-lg-4">
              <Summary
                nextPage="summarypage"
                // setProceedClick={setProceedClick}
                callBack={callApi}
                planInfo={apiResponse}
                apiResponse={apiResponse}
                setApiResponse={setApiResponse}
                addonsArray={addonArr}
                setAddonArr={setAddonArr}
                setStopLoading={setStopLoading}
                // goKyc={goKyc}
                kycData={kycData}
                validateKycForm={validateKycForm}
                addondataList={addonDataList}
                setAddonDataList={setAddonDataList}
                settingData={settingData}
                finalAddonList={finalAddonList}
                setFinalAddonList={setFinalAddonList}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default KYCfrom;
